import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import ReportHero from "./ReportHero";
import { getAuthCredentials } from "../../utils/auth";
import generateZReadingPDF from "./Format/ZReading";
import generateXReadingPDF from "./Format/XReading";

const ReportRecords = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [receiptConfig, setReceiptConfig] = useState(null);
  const { token } = getAuthCredentials();

  const fetchRecords = async (date = "", tab = activeTab) => {
    if (!date) {
      return;
    }
    try {
      setLoading(true);

      setRecords([]);
      setFilteredRecords([]);

      const endpoint =
        tab === 0
          ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/xreadings`
          : `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/zreadings`;

      const response = await axios.get(endpoint, {
        params: { date, page: 1, limit: 10 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRecords(tab === 0 ? response.data.xReadings : response.data.zReadings);
      setFilteredRecords(
        tab === 0 ? response.data.xReadings : response.data.zReadings
      );
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    fetchRecords(searchDate, newValue);
  };

  const handleDateSearch = (e) => {
    const value = e.target.value;
    setSearchDate(value);
    setRecords([]);
    setFilteredRecords([]);
    fetchRecords(value);
  };

  const handleViewClick = (record) => {
    if (!receiptConfig) {
      console.error("Receipt configuration not found.");
      return;
    }

    if (!record) {
      console.error("Record data not found.");
      return;
    }

    try {
      const generatePDF =
        activeTab === 0 ? generateXReadingPDF : generateZReadingPDF;
      const pdfUrl = generatePDF(receiptConfig, record);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    if (!searchDate) {
      const currentDate = new Date().toISOString().split("T")[0];
      setSearchDate(currentDate);
      fetchRecords(currentDate, activeTab);
    }
  }, []);

  useEffect(() => {
    fetchRecords(searchDate, activeTab);
  }, [activeTab]);

  useEffect(() => {
    const fetchReceiptConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setReceiptConfig(response.data.receiptConfig || null);
      } catch (error) {
        console.error("Error fetching receipt configuration:", error);
        setReceiptConfig(null);
      }
    };

    fetchReceiptConfig();
  }, [token]);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Typography variant="h5" textAlign="center" gutterBottom>
        {activeTab === 0 ? "X-Reading Records" : "Z-Reading Records"}
      </Typography>
      {/* Tabs for X-Reading and Z-Reading */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ marginBottom: 3 }}
      >
        <Tab label="X-Reading" />
        <Tab label="Z-Reading" />
      </Tabs>
      {/* Date Search Bar */}
      <Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
        <TextField
          label="Search by Date"
          type="date"
          value={searchDate}
          onChange={handleDateSearch}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          sx={{ maxWidth: 400 }}
        />
      </Box>
      {/* Records Table */}
      <Paper elevation={3} sx={{ padding: 2 }}>
        {loading ? (
          <Typography variant="body1" textAlign="center">
            Loading records...
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {activeTab === 0 && <TableCell>Shift</TableCell>}
                <TableCell>Total Sales</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.map((record) => (
                <TableRow key={record._id}>
                  <TableCell>
                    {new Date(
                      record.date || record.startDate
                    ).toLocaleDateString()}
                  </TableCell>
                  {activeTab === 0 && (
                    <TableCell>
                      {record.startDate && record.endDate
                        ? `${new Date(record.startDate).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })} - ${new Date(record.endDate).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}`
                        : "N/A"}
                    </TableCell>
                  )}
                  <TableCell>
                    {record.grossSales?.toFixed(2) ||
                      record.totalSales?.toFixed(2) ||
                      "N/A"}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View">
                      <IconButton onClick={() => handleViewClick(record)}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {filteredRecords.length === 0 && !loading && (
          <Typography
            variant="body1"
            textAlign="center"
            color="textSecondary"
            sx={{ marginTop: 2 }}
          >
            No records found.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default ReportRecords;
