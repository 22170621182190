import jsPDF from "jspdf";

const generateXReadingTextFile = (receiptConfig, xReading) => {
  let textContent = "";

  // Add Header
  textContent += `${receiptConfig.companyName || "N/A"}\n`;
  textContent += `Owned and Operated By:\n`;
  textContent += `${receiptConfig.storeName || "N/A"}\n`;
  textContent += `${receiptConfig.address || "N/A"}\n`;
  textContent += `Vat Registered TIN: ${receiptConfig.TIN || "N/A"}\n`;
  textContent += `SN: ${receiptConfig.SN || "N/A"} MIN: ${
    receiptConfig.MIN || "N/A"
  }\n`;
  textContent += "========================================\n";

  // Z-Reading Details
  textContent += `----------------------------------------\n`;
  textContent += `Reset Counter No.: Non-Resettable\n`;
  textContent += `Shift-end Report X-Reading#: ${xReading._id || "N/A"}\n`;
  textContent += `Administrator: ${xReading.administrator || "N/A"}\n`;
  textContent += `Cashier: AUTO-GENERATED\n`;
  textContent += `Start Date/Time: ${
    xReading.startDate ? new Date(xReading.startDate).toLocaleString() : "N/A"
  }\n`;
  textContent += `End Date/Time: ${
    xReading.endDate ? new Date(xReading.endDate).toLocaleString() : "N/A"
  }\n`;
  textContent += `Date/Time: ${
    xReading.createdAt ? new Date(xReading.createdAt).toLocaleString() : "N/A"
  }\n`;
  textContent += `Terminal Code: 001\n`;
  textContent += `Guest Count: ${xReading.totalTransactions || 0}\n`;
  textContent += `----------------------------------------\n`;

  // Sales Summary
  textContent += `            SALES SUMMARY REPORT\n`;
  textContent += `Gross Sales:          ${String(
    xReading.totalTransactions || 0
  ).padEnd(5)}    ${String(xReading.grossSales?.toFixed(2) || "0.00").padStart(
    7
  )}\n`;
  textContent += `Senior Discounts:     ${String(
    xReading.seniorTransactionCount || 0
  ).padEnd(5)}   -${String(
    xReading.seniorDiscounts?.toFixed(2) || "0.00"
  ).padStart(7)}\n`;
  textContent += `SC VAT Deduction:     ${String(
    xReading.seniorTransactionCount || 0
  ).padEnd(5)}   -${String(
    xReading.scVatDeduction?.toFixed(2) || "0.00"
  ).padStart(7)}\n`;
  textContent += `PWD Discounts:        ${String(
    xReading.pwdTransactionCount || 0
  ).padEnd(5)}   -${String(
    xReading.pwdDiscounts?.toFixed(2) || "0.00"
  ).padStart(7)}\n`;
  textContent += `PWD VAT Deduction:    ${String(
    xReading.pwdTransactionCount || 0
  ).padEnd(5)}   -${String(
    xReading.pwdVatDeduction?.toFixed(2) || "0.00"
  ).padStart(7)}\n`;
  textContent += `Athlete Discounts:    ${String(
    xReading.athleteTransactionCount || 0
  ).padEnd(5)}   -${String(
    xReading.athleteDiscounts?.toFixed(2) || "0.00"
  ).padStart(7)}\n`;
  textContent += `----------------------------------------\n`;
  textContent += `Sub-Total:                  ${String(
    xReading.subTotal?.toFixed(2) || "0.00"
  ).padStart(10)}\n`;
  textContent += `----------------------------------------\n`;
  textContent += `Net Total:                  ${String(
    xReading.netTotal?.toFixed(2) || "0.00"
  ).padStart(10)}\n`;
  textContent += `----------------------------------------\n`;

  // VAT Summary
  textContent += `      TAX DECLARATION SUMMARY REPORT\n`;
  textContent += `VATable Sales:                  ${
    xReading.vatSales?.toFixed(2) || "0.00"
  }\n`;
  textContent += `VAT Amount:                      ${
    xReading.vatAmount?.toFixed(2) || "0.00"
  }\n`;
  textContent += `VAT Exempt Sales:               ${
    xReading.vatExemptSales?.toFixed(2) || "0.00"
  }\n`;
  textContent += `Net of VAT Exempt:              ${
    xReading.netOfVatExempt?.toFixed(2) || "0.00"
  }\n`;
  textContent += `Zero-Rated Sales:                 ${
    xReading.zeroRatedSales?.toFixed(2) || "0.00"
  }\n`;
  textContent += `----------------------------------------\n`;

  // Mandated VAT & Discount Report
  textContent += `     MANDATED VAT & DISCOUNT REPORT\n`;
  textContent += `SC/PWD/Diplomat TRN Count:        ${
    xReading.scPwdDiplomatTransactionCount || 0
  }\n`;
  textContent += `Senior Discount:                  ${
    xReading.seniorDiscounts?.toFixed(2) || "0.00"
  }\n`;
  textContent += `SC VAT Deduction:                 ${
    xReading.scVatDeduction?.toFixed(2) || "0.00"
  }\n`;
  textContent += `PWD Discount:                   ${
    xReading.pwdDiscounts?.toFixed(2) || "0.00"
  }\n`;
  textContent += `PWD VAT Deduction:               ${
    xReading.pwdVatDeduction?.toFixed(2) || "0.00"
  }\n`;
  textContent += `----------------------------------------\n`;

  // Transaction Details
  textContent += `          TRANSACTION DETAILS\n`;
  textContent += `Beginning Inv. No.:               ${
    xReading.beginningInvoiceNo || "N/A"
  }\n`;
  textContent += `Ending Inv. No.:                  ${
    xReading.endingInvoiceNo || "N/A"
  }\n`;
  textContent += `Total:                            ${
    xReading.totalInvoiceCount || 0
  }\n`;
  textContent += `Open Drawer Count:                ${
    xReading.openDrawerCount || 0
  }\n`;
  textContent += `Void Items:              ${
    xReading.voidItemsCount || 0
  }        ${xReading.voidItemsAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `Refunds:                 ${
    xReading.refundsCount || 0
  }        ${xReading.refundsAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Tender Summary
  textContent += `          TENDER SUMMARY REPORT\n`;
  xReading.tenderSummary?.forEach((tender) => {
    textContent += `${tender.type.padEnd(20)} ${tender.count || 0} ${
      tender.amount?.toFixed(2) || "0.00"
    }\n`;
  });
  textContent += `Total Tenders:           ${
    xReading.totalTendersCount || 0
  }        ${xReading.totalTendersAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Cash Breakdown
  textContent += `           CASH BREAKDOWN REPORT\n`;
  xReading.cashBreakdown?.forEach((denomination) => {
    textContent += `${denomination.type.padEnd(20)} ${
      denomination.count || 0
    } ${denomination.amount?.toFixed(2) || "0.00"}\n`;
  });
  textContent += `        TOTAL:                    ${
    xReading.totalCashBreakdown?.toFixed(2) || "0.00"
  }\n`;
  textContent += `   SHORT/OVER:                    ${
    xReading.shortOver?.toFixed(2) || "0.00"
  }\n`;
  textContent += `----------------------------------------\n`;

  // Sales per Cashier
  textContent += `     SALES PER CASHIER SUMMARY REPORT\n`;
  xReading.cashierSalesSummary?.forEach((cashier) => {
    textContent += `${cashier.name.padEnd(20)} ${
      cashier.amount?.toFixed(2) || "0.00"
    }\n`;
  });
  textContent += `Total Cashier Sales:              ${
    xReading.totalCashierSales?.toFixed(2) || "0.00"
  }\n`;
  textContent += `----------------------------------------\n`;

  // Hourly Sales Summary
  textContent += `      HOURLY SALES SUMMARY REPORT\n`;
  textContent += `----------------------------------------\n`;
  textContent += `HOUR  TRN. COUNT  QTY. SOLD       AMOUNT\n`;

  let totalTransactions = 0;
  let totalQuantity = 0;
  let totalAmount = 0;

  xReading.hourlySales?.forEach((hourData) => {
    if (
      hourData.transactionCount === 0 &&
      hourData.quantitySold === 0 &&
      hourData.amount === 0
    ) {
      return; // Skip if no sales
    }

    const formattedAmount = hourData.amount?.toFixed(2) || "0.00";
    textContent += `${hourData.hour.padEnd(6)}  ${hourData.transactionCount
      .toString()
      .padStart(3)}       ${hourData.quantitySold
      .toString()
      .padStart(5)}     ${formattedAmount.padStart(10)}\n`;

    totalTransactions += hourData.transactionCount || 0;
    totalQuantity += hourData.quantitySold || 0;
    totalAmount += hourData.amount || 0;
  });

  // Add the totals row
  textContent += `----------------------------------------\n`;
  textContent += `Total:  ${totalTransactions
    .toString()
    .padStart(3)}       ${totalQuantity
    .toString()
    .padStart(5)}     ${totalAmount.toFixed(2).padStart(10)}\n`;

  // Footer
  textContent += `========================================\n`;
  textContent += `POS PROVIDER: SparkleStar International Corporation\n`;

  // Create a Blob and trigger the download of the text file
  const blob = new Blob([textContent], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "xReadingReport.txt";
  link.click();

  const generatePDFFromText = (textContent) => {
    const doc = new jsPDF();
    const pageHeight = 280;
    const margin = 10;
    let yOffset = margin;

    const lines = textContent.split("\n");

    lines.forEach((line) => {
      const lineHeight = line.length > 60 ? 12 : 10;

      if (yOffset + lineHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }

      doc.text(line, 10, yOffset);
      yOffset += lineHeight;
    });

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, "_blank");
  };

  generatePDFFromText(textContent);
};

export default generateXReadingTextFile;
