import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AccountHero from "./AccountHero";
import {
  mergeLocalDBInventory,
  mergeLocalDBExpense,
  mergeLocalDBMenuItems,
  mergeLocalDBUsers,
} from "./Functions/AccountImport"; 
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const AccountImportExport = () => {
  const [importStatus, setImportStatus] = useState("");

  const handleExport = () => {
    const exportData = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key === "localDB" || key.startsWith("localDB_")) {
        exportData[key] = ls.get(key);
      }
    }

    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "localDB_backup.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);

        Object.keys(importedData).forEach((key) => {
          const currentData = ls.get(key) || {};
          const newData = importedData[key];

          if (key === "localDB_inventory") {
            ls.set(key, mergeLocalDBInventory(currentData, newData));
          } else if (key === "localDB_expense") {
            ls.set(key, mergeLocalDBExpense(currentData, newData));
          } else if (key === "localDB_menuItems") {
            ls.set(key, mergeLocalDBMenuItems(currentData, newData));
          } else if (key === "localDB") {
            ls.set(key, mergeLocalDBUsers(currentData, newData));
          } else {
            ls.set(key, newData); 
          }
        });

        setImportStatus("Import and merge successful!");
      } catch (error) {
        setImportStatus("Failed to import data.");
        console.error(error);
      }
    };

    reader.readAsText(file);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <AccountHero title="Import / Export LocalDB" />

      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExport}
          sx={{ marginRight: 2 }}
        >
          Export LocalDB
        </Button>

        <input
          accept=".json"
          id="import-file"
          type="file"
          style={{ display: "none" }}
          onChange={handleImport}
        />
        <label htmlFor="import-file">
          <Button variant="contained" component="span" color="secondary">
            Import LocalDB
          </Button>
        </label>

        {importStatus && (
          <Typography
            variant="body1"
            color={importStatus.includes("successful") ? "green" : "red"}
            sx={{ marginTop: 2 }}
          >
            {importStatus}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AccountImportExport;
