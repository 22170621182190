import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Button, Paper, Divider } from "@mui/material";
import {
  BarChart,
  Inventory,
  Category,
  Lock,
  Person,
  AccountCircle,
  Kitchen,
  Restaurant,
  Fastfood,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const DashboardCard = ({ title, icon: Icon, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        width: "100%",
        padding: 4,
        textAlign: "center",
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        height: "100%",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Icon sx={{ fontSize: 60, marginBottom: 2 }} />
        <Typography variant="h6">{title}</Typography>
      </Paper>
    </Button>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={4}
        sx={{ gap: 2 }}
      >
        <AccountCircle sx={{ fontSize: 100 }} />
        <Typography variant="h4" textAlign="center">
          POS Client Dashboard
        </Typography>
      </Box>

      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard
            title="Sales Reports"
            icon={BarChart}
            onClick={() => navigate("/demo/sales")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard
            title="Inventory Management"
            icon={Inventory}
            onClick={() => navigate("/demo/inventory")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard
            title="Menu Items Creation"
            icon={Category}
            onClick={() => navigate("/demo/menucreation")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard title="Access" icon={Lock} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard
            title="Account Management"
            icon={Person}
            onClick={() => navigate("/demo/account-management")}
          />
        </Grid>
      </Grid>

      <Box mt={4} mb={2}>
        <Divider />
        <Typography variant="h5" textAlign="center" sx={{ mt: 2 }}>
          Views
        </Typography>
        <Divider />
      </Box>

      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard title="Kitchen" icon={Kitchen} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard title="Kiosk" icon={Fastfood} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <DashboardCard title="Dining" icon={Restaurant} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
