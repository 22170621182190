import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "../demo/pages/auth/Login";
import Dashboard from "../demo/pages/dashboard/Dashboard";
import Sales from "../demo/pages/sales/Sales";
import Inventory from "../demo/pages/Inventory/Inventory";
import InventoryArchive from "../demo/pages/Inventory/InventoryArchive";
import InventoryHistory from "../demo/pages/Inventory/InventoryHistory";
import MenuCreation from "../demo/pages/menucreation/MenuCreation";
import MenuArchive from "../demo/pages/menucreation/MenuArchive";
import AccountManagement from "../demo/pages/account/AccountManagement";
import AccountImportExport from "../demo/pages/account/AccountImportExport";

const DemoRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/demo/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/inventory/archive" element={<InventoryArchive />} />
      <Route path="/inventory/history" element={<InventoryHistory />} />
      <Route path="/menucreation" element={<MenuCreation />} />
      <Route path="/menucreation/archived" element={<MenuArchive />} />
      <Route path="/account-management" element={<AccountManagement />} />
      <Route path="/import_export" element={<AccountImportExport />} />
    </Routes>
  );
};

export default DemoRoutes;
