export const salesData = {
  labels: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  datasets: [
    {
      label: "Sales in PHP",
      data: [1500, 2200, 1800, 2400, 2700, 3200, 2300], 
      backgroundColor: "rgba(75, 192, 192, 0.6)",
    },
  ],
};

export const pieData = {
  labels: ["Street Foods", "Drinks", "Sari-sari Products"],
  datasets: [
    {
      label: "Category Sales",
      data: [65, 25, 10], 
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

export const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

export const pieOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

export const transactionLogs = [
  {
    id: 1,
    date: "2024-09-01",
    amount: "₱150.00",
    item: "Banana Cue, Softdrinks",
  },
  { id: 2, date: "2024-09-02", amount: "₱90.00", item: "Fish Balls, Taho" },
  { id: 3, date: "2024-09-03", amount: "₱120.00", item: "Siomai, Iced Tea" },
  {
    id: 4,
    date: "2024-09-04",
    amount: "₱200.00",
    item: "Instant Noodles, Canned Goods",
  },
  {
    id: 5,
    date: "2024-09-05",
    amount: "₱160.00",
    item: "Empanada, Bottled Water",
  },
];
