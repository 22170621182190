import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const EditTagsItems = ({ open, onClose, menuItem, onSave }) => {
  const [tags, setTags] = useState(menuItem.tags || []);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    const updatedItem = { ...menuItem, tags };
    onSave(updatedItem);
  }, [tags]);

  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);

      const storedMenuData = ls.get("localDB_menuItems") || { tags: {} };
      const allTags = storedMenuData.tags || {};
      if (allTags[newTag]) {
        allTags[newTag].count += 1;
      } else {
        allTags[newTag] = { count: 1, status: true };
      }
      storedMenuData.tags = allTags;
      ls.set("localDB_menuItems", storedMenuData);
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(updatedTags);

    const storedMenuData = ls.get("localDB_menuItems") || { tags: {} };
    const allTags = storedMenuData.tags || {};
    if (allTags[tagToDelete] && allTags[tagToDelete].count > 1) {
      allTags[tagToDelete].count -= 1;
    } else {
      delete allTags[tagToDelete];
    }
    storedMenuData.tags = allTags;
    ls.set("localDB_menuItems", storedMenuData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Tags</DialogTitle>
      <DialogContent>
        {tags.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                deleteIcon={<DeleteIcon />}
                color="primary"
              />
            ))}
          </Box>
        ) : (
          <Typography>No Tags Assigned</Typography>
        )}

        <Box sx={{ display: "flex", marginTop: 2 }}>
          <TextField
            label="New Tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            sx={{ width: "80%" }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={handleAddTag}
            sx={{ marginLeft: 1, width: "20%" }}
          >
            Add Tag
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTagsItems;
