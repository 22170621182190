import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const EditOptionItems = ({ open, onClose, onSave, menuItem }) => {
  const [options, setOptions] = useState([]); // Now stores multiple option groups
  const [ingredients, setIngredients] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [optionGroups, setOptionGroups] = useState([
    {
      optionName: "",
      optionType: "single", // single or checkbox
      choices: [
        { name: "", price: "", selectedType: "ingredient", selectedItem: "" },
      ],
    },
  ]); // Store multiple option groups here

  // Load stored ingredients and menu items
  useEffect(() => {
    const storedIngredients = ls.get("localDB_inventory")?.items || [];
    const storedMenuData = ls.get("localDB_menuItems") || { menu: [] };
    const storedMenuItems = storedMenuData.menu || [];

    setIngredients(storedIngredients);
    setMenuItems(storedMenuItems);
  }, []);

  // Load options when menuItem changes (populating existing options)
  useEffect(() => {
    if (menuItem?.options?.length > 0) {
      const existingOptions = menuItem.options.map((option) => ({
        optionName: option.name,
        optionType: option.type,
        choices: option.choices.map((choice) => ({
          name: choice.name,
          price: choice.price || "",
          selectedType: choice.additionalItem
            ? "ingredient" // Assuming ingredient, you can refine this logic
            : "menuItem",
          selectedItem: choice.additionalItem ? choice.additionalItem.name : "",
          ingredientAmount: choice.ingredientAmount || "", // Assuming amount handling here
        })),
      }));
      setOptionGroups(existingOptions);
    }
  }, [menuItem]);

  const handleAddChoice = (groupIndex) => {
    const updatedGroups = [...optionGroups];
    updatedGroups[groupIndex].choices.push({
      name: "",
      price: "",
      selectedType: "ingredient",
      selectedItem: "",
    });
    setOptionGroups(updatedGroups);
  };

  const handleRemoveChoice = (groupIndex, choiceIndex) => {
    const updatedGroups = [...optionGroups];
    updatedGroups[groupIndex].choices = updatedGroups[
      groupIndex
    ].choices.filter((_, i) => i !== choiceIndex);
    setOptionGroups(updatedGroups);
  };

  const handleAddOptionGroup = () => {
    setOptionGroups([
      ...optionGroups,
      {
        optionName: "",
        optionType: "single", // single or checkbox
        choices: [
          { name: "", price: "", selectedType: "ingredient", selectedItem: "" },
        ],
      },
    ]);
  };

  const handleRemoveOptionGroup = (groupIndex) => {
    const updatedGroups = optionGroups.filter((_, i) => i !== groupIndex);
    setOptionGroups(updatedGroups);
  };

  const handleSaveOption = () => {
    const newOptions = optionGroups.map((group) => ({
      name: group.optionName,
      type: group.optionType,
      choices: group.choices.map((choice) => ({
        name: choice.name,
        price: choice.price || 0,
        additionalItem:
          choice.selectedType === "ingredient"
            ? ingredients.find((ing) => ing.name === choice.selectedItem)
            : menuItems.find((item) => item.name === choice.selectedItem),
      })),
    }));

    const updatedMenuItem = { ...menuItem, options: newOptions };
    onSave(updatedMenuItem);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Options</DialogTitle>
      <DialogContent>
        {optionGroups.map((group, groupIndex) => (
          <Accordion key={groupIndex} defaultExpanded={groupIndex === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${groupIndex}-content`}
              id={`panel${groupIndex}-header`}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Typography>
                  {group.optionName || `Option Group ${groupIndex + 1}`}
                </Typography>
              </Box>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the accordion from toggling when clicked
                  handleRemoveOptionGroup(groupIndex);
                }}
                sx={{
                  color: "#f44336",
                  borderColor: "#f44336",
                  "&:hover": {
                    backgroundColor: "#f4433620",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Option Name"
                fullWidth
                margin="normal"
                value={group.optionName}
                onChange={(e) => {
                  const updatedGroups = [...optionGroups];
                  updatedGroups[groupIndex].optionName = e.target.value;
                  setOptionGroups(updatedGroups);
                }}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Option Type</InputLabel>
                <Select
                  value={group.optionType}
                  onChange={(e) => {
                    const updatedGroups = [...optionGroups];
                    updatedGroups[groupIndex].optionType = e.target.value;
                    setOptionGroups(updatedGroups);
                  }}
                >
                  <MenuItem value="single">Single Select (Radio)</MenuItem>
                  <MenuItem value="checkbox">
                    Multiple Select (Checkbox)
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Choices Section */}
              {group.choices.map((choice, choiceIndex) => (
                <Accordion
                  key={choiceIndex}
                  defaultExpanded={false}
                  sx={{ marginBottom: 2 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${groupIndex}-choice${choiceIndex}-content`}
                    id={`panel${groupIndex}-choice${choiceIndex}-header`}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Typography>
                        {choice.name || `Choice ${choiceIndex + 1}`}
                      </Typography>
                    </Box>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveChoice(groupIndex, choiceIndex);
                      }}
                      sx={{
                        color: "#f44336",
                        borderColor: "#f44336",
                        "&:hover": {
                          backgroundColor: "#f4433620",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          label="Choice Name"
                          fullWidth
                          value={choice.name}
                          onChange={(e) => {
                            const updatedGroups = [...optionGroups];
                            updatedGroups[groupIndex].choices[
                              choiceIndex
                            ].name = e.target.value;
                            setOptionGroups(updatedGroups);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Price"
                          fullWidth
                          value={choice.price}
                          onChange={(e) => {
                            const updatedGroups = [...optionGroups];
                            updatedGroups[groupIndex].choices[
                              choiceIndex
                            ].price = e.target.value;
                            setOptionGroups(updatedGroups);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={choice.selectedType}
                            onChange={(e) => {
                              const updatedGroups = [...optionGroups];
                              updatedGroups[groupIndex].choices[
                                choiceIndex
                              ].selectedType = e.target.value;
                              setOptionGroups(updatedGroups);
                            }}
                          >
                            <MenuItem value="ingredient">Ingredient</MenuItem>
                            <MenuItem value="menuItem">Menu Item</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel>
                            {choice.selectedType === "ingredient"
                              ? "Select Ingredient"
                              : "Select Menu Item"}
                          </InputLabel>
                          <Select
                            value={choice.selectedItem}
                            onChange={(e) => {
                              const updatedGroups = [...optionGroups];
                              updatedGroups[groupIndex].choices[
                                choiceIndex
                              ].selectedItem = e.target.value;
                              setOptionGroups(updatedGroups);
                            }}
                          >
                            {choice.selectedType === "ingredient"
                              ? ingredients.map((ingredient) => (
                                  <MenuItem
                                    key={ingredient.name}
                                    value={ingredient.name}
                                  >
                                    {ingredient.name}
                                  </MenuItem>
                                ))
                              : menuItems.map((item) => (
                                  <MenuItem key={item.name} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {choice.selectedType === "ingredient" && (
                        <Grid item xs={6}>
                          <TextField
                            label="Ingredient Amount"
                            fullWidth
                            value={choice.ingredientAmount || 0}
                            onChange={(e) => {
                              const updatedGroups = [...optionGroups];
                              updatedGroups[groupIndex].choices[
                                choiceIndex
                              ].ingredientAmount = e.target.value;
                              setOptionGroups(updatedGroups);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddChoice(groupIndex)}
                  sx={{ marginRight: 2 }}
                >
                  Add Choice
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddOptionGroup}
          sx={{ marginTop: 2 }}
        >
          Add Option Group
        </Button>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveOption} color="primary">
          Save Options
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOptionItems;
