import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const AddInventoryDialog = ({ open, onClose, onAdd }) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [amountOrdered, setAmountOrdered] = useState("");
  const [currentAmount, setCurrentAmount] = useState("");
  const [price, setPrice] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  useEffect(() => {
    const storedInventory = ls.get("localDB_inventory") || {
      categories: [],
      items: [],
    };
    setCategories(storedInventory.categories);
    setInventory(storedInventory.items);
  }, []);

  const handleAddCategory = (e) => {
    if (e.key === "Enter" && newCategory) {
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);
      setCategory(newCategory);
      setNewCategory("");
      setIsAddingCategory(false);

      const storedInventory = ls.get("localDB_inventory") || {
        categories: [],
        items: [],
      };
      storedInventory.categories = updatedCategories;
      ls.set("localDB_inventory", storedInventory);
    }
  };

  const handleDeleteCategory = (cat) => {
    const hasAssignedItems = inventory.some((item) => item.category === cat);

    if (hasAssignedItems) {
      alert("Category has items");
    } else {
      const updatedCategories = categories.filter(
        (category) => category !== cat
      );
      setCategories(updatedCategories);

      const storedInventory = ls.get("localDB_inventory") || {
        categories: [],
        items: [],
      };
      storedInventory.categories = updatedCategories;
      ls.set("localDB_inventory", storedInventory);
    }
  };

  const handleAdd = () => {
    const newItem = {
      name,
      category,
      amountOrdered,
      currentAmount: amountOrdered,
      price,
      deliveryDate,
      expiryDate,
    };

    onAdd(newItem);

    const storedInventory = ls.get("localDB_inventory") || {
      history: [],
      items: [],
    };

    const addedItem = storedInventory.items.find(
      (item) => item.name === newItem.name && item.category === newItem.category
    );

    const newLog = {
      id: addedItem ? addedItem.id : "N/A",
      name: newItem.name,
      deliveryDate: newItem.deliveryDate,
      expiryDate: newItem.expiryDate,
      dateModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      action: "Added",
    };

    storedInventory.history = [...(storedInventory.history || []), newLog];
    ls.set("localDB_inventory", storedInventory);

    const storedExpense = ls.get("localDB_expense") || { expenses: [] };
    const newExpense = {
      id: addedItem ? addedItem.id : "N/A",
      category: newItem.category,
      name: newItem.name,
      price: newItem.price,
      date: dayjs().format("YYYY-MM-DD"),
    };
    storedExpense.expenses = [...(storedExpense.expenses || []), newExpense];
    ls.set("localDB_expense", storedExpense);

    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
    setAmountOrdered("");
    setCurrentAmount("");
    setPrice("");
    setDeliveryDate("");
    setExpiryDate("");
  };

  const isFormValid =
    name && category && amountOrdered && price && deliveryDate && expiryDate;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => selected}
          >
            {categories.map((cat, index) => (
              <MenuItem
                key={index}
                value={cat}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat}
                <Tooltip
                  title={
                    inventory.some((item) => item.category === cat)
                      ? "Category has items"
                      : "Delete category"
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <TextField
            margin="normal"
            label="New Category"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyDown={handleAddCategory}
            helperText="Press Enter to add a new category"
          />
        )}

        <TextField
          margin="normal"
          label="Amount Ordered"
          type="number"
          fullWidth
          value={amountOrdered}
          onChange={(e) => setAmountOrdered(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Price"
          type="number"
          fullWidth
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetFields();
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary" disabled={!isFormValid}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryDialog;
