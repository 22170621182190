import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import AddCategoryDialog from "./AddCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const EditInventoryDialog = ({
  open,
  onClose,
  item,
  categories = [],
  fetchCategories,
  fetchInventory,
  setCategories,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.inventoryName || "");
      setCategory(item.categoryId || "");
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      const changes =
        item.inventoryName !== name || item.categoryId !== category;
      setHasChanges(changes);
    }
  }, [name, category, item]);

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const token = ls.get("authToken");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchCategories(token);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Category has been successfully deleted.",
      });
    } catch (error) {
      console.error("Error deleting category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete category. Please try again.",
      });
    }
  };

  const handleEdit = async () => {
    const updatedItem = {
      name,
      category,
    };

    try {
      const user = ls.get("user");
      const token = ls.get("authToken");
      const posId = user?.posId;

      if (!token || !posId) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You must be logged in to edit an item.",
        });
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/edit/${item._id}`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchInventory();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Item updated successfully.",
      });
    } catch (error) {
      console.error("Error updating item:", error);

      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Failed to update item. Please try again.",
      });
    }

    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category || ""}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              const selectedCategory = categories.find(
                (cat) => cat.categoryId === selected
              );
              return selectedCategory ? selectedCategory.categoryName : "";
            }}
          >
            {categories.map((cat) => (
              <MenuItem
                key={cat.categoryId}
                value={cat.categoryId}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat.categoryName}
                <Tooltip title={"Delete category"}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <AddCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setCategory(newCategory.categoryId);
              setIsAddingCategory(false);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary" disabled={!hasChanges}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryDialog;
