import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
} from "@mui/material";

const AddEditReceiptConfig = ({
  open,
  onClose,
  onSave,
  receiptConfig,
  user,
  loading,
}) => {
  const [formData, setFormData] = useState({
    companyName: "",
    storeName: "",
    address: "",
    TIN: "",
    SN: "",
    MIN: "",
    storeNumber: "",
    storeEmail: "",
  });

  const [originalData, setOriginalData] = useState(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    if (receiptConfig) {
      setFormData(receiptConfig);
      setOriginalData(receiptConfig);
    } else {
      const initialData = {
        companyName: user?.companyname || "",
        storeName: "",
        address: "",
        TIN: "",
        SN: "",
        MIN: "",
        storeNumber: "",
        storeEmail: "",
      };
      setFormData(initialData);
      setOriginalData(initialData);
    }
  }, [receiptConfig, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      const isComplete =
        updatedData.companyName.trim() !== "" &&
        updatedData.storeName.trim() !== "" &&
        updatedData.address.trim() !== "" &&
        updatedData.TIN.trim() !== "" &&
        updatedData.SN.trim() !== "" &&
        updatedData.MIN.trim() !== "" &&
        updatedData.storeNumber.trim() !== "" &&
        updatedData.storeEmail.trim() !== "";

      const hasChanges =
        JSON.stringify(updatedData) !== JSON.stringify(originalData);

      setIsFormComplete(isComplete);
      setIsFormChanged(hasChanges);

      return updatedData;
    });
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {receiptConfig
          ? "Edit Receipt Configuration"
          : "Add Receipt Configuration"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Company Name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Store Name"
            name="storeName"
            value={formData.storeName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="TIN"
            name="TIN"
            value={formData.TIN}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="SN"
            name="SN"
            value={formData.SN}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="MIN"
            name="MIN"
            value={formData.MIN}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Store Contact Number"
            name="storeNumber"
            value={formData.storeNumber}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Store Email"
            name="storeEmail"
            value={formData.storeEmail}
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={!isFormComplete || !isFormChanged || loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditReceiptConfig;
