import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import axios from "axios";
import { getAuthCredentials } from "../../utils/auth";
import AddEditReceiptConfig from "./Dialog/AddEditReceiptConfig";
import ReceiptHero from "./ReceiptHero";
import Swal from "sweetalert2";

const ReceiptConfig = () => {
  const [receiptConfig, setReceiptConfig] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token, user } = getAuthCredentials();

  useEffect(() => {
    const fetchReceiptConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setReceiptConfig(response.data.receiptConfig || null);
      } catch (error) {
        console.error("Error fetching receipt configuration:", error);
        setReceiptConfig(null);
      }
    };

    fetchReceiptConfig();
  }, [token]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveConfig = async (formData) => {
    setLoading(true); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReceiptConfig(response.data.receiptConfig);
      handleDialogClose();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Receipt configuration saved successfully.",
      });
    } catch (error) {
      console.error("Error saving receipt configuration:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save receipt configuration.",
      });
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ReceiptHero />

      <Box
        sx={{
          padding: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {!receiptConfig ? (
          <>
            <Typography variant="h5" gutterBottom>
              No Receipt Configuration Yet
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogOpen}
              sx={{ marginTop: 2 }}
            >
              Start Receipt Configure
            </Button>
          </>
        ) : (
          <Paper sx={{ padding: 4, width: "100%" }}>
            <Typography variant="h6" gutterBottom>
              Receipt Configuration
            </Typography>
            <Typography>
              Company Name: {receiptConfig.companyName || "N/A"}
            </Typography>
            <Typography>
              Store Name: {receiptConfig.storeName || "N/A"}
            </Typography>
            <Typography>Address: {receiptConfig.address || "N/A"}</Typography>
            <Typography>TIN: {receiptConfig.TIN || "N/A"}</Typography>
            <Typography>SN: {receiptConfig.SN || "N/A"}</Typography>
            <Typography>MIN: {receiptConfig.MIN || "N/A"}</Typography>
            <Typography>
              Store Number: {receiptConfig.storeNumber || "N/A"}
            </Typography>
            <Typography>
              Store Email: {receiptConfig.storeEmail || "N/A"}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogOpen}
              sx={{ marginTop: 2 }}
            >
              Edit Receipt Configuration
            </Button>
          </Paper>
        )}
        <AddEditReceiptConfig
          open={openDialog}
          onClose={handleDialogClose}
          onSave={handleSaveConfig}
          receiptConfig={receiptConfig}
          user={user}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default ReceiptConfig;
