import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import SecureLS from "secure-ls";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InventoryHero from "./InventoryHero";

const ls = new SecureLS({ encodingType: "aes" });

const InventoryHistory = () => {
  const [history, setHistory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("dateModified");
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));

  const fetchData = async () => {
    try {
      const user = ls.get("user");
      const token = ls.get("authToken");
      const posId = user?.posId;

      if (!token || !posId) {
        console.error("No token or posId found.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/history`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        }
      );

      setHistory(response.data.history || []);
    } catch (error) {
      console.error("Error fetching inventory history:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortHistory = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="h5" gutterBottom>
        History
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
        </Box>
      </LocalizationProvider>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === "action" ? order : false}>
                <TableSortLabel
                  active={orderBy === "action"}
                  direction={orderBy === "action" ? order : "asc"}
                  onClick={() => handleSort("action")}
                >
                  Action
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "item" ? order : false}>
                <TableSortLabel
                  active={orderBy === "item"}
                  direction={orderBy === "item" ? order : "asc"}
                  onClick={() => handleSort("item")}
                >
                  Item
                </TableSortLabel>
              </TableCell>
              <TableCell>Changes</TableCell>
              <TableCell
                sortDirection={orderBy === "dateModified" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "dateModified"}
                  direction={orderBy === "dateModified" ? order : "asc"}
                  onClick={() => handleSort("dateModified")}
                >
                  Date Modified
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortHistory(history, getComparator(order, orderBy)).map(
              (log, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color:
                        log.inventoryLog.action === "Created"
                          ? "green"
                          : log.inventoryLog.action === "Archived" ||
                            log.inventoryLog.action === "Archived Instance"
                          ? "purple"
                          : log.inventoryLog.action === "Unarchived" ||
                            log.inventoryLog.action === "Unarchived Instance" ||
                            log.inventoryLog.action === "Replenishment"
                          ? "green"
                          : log.inventoryLog.action === "Updated Instance" ||
                            log.inventoryLog.action === "Updated"
                          ? "blue"
                          : log.inventoryLog.action === "Deleted"
                          ? "red"
                          : "inherit",
                    }}
                  >
                    {log.inventoryLog.action}
                  </TableCell>
                  <TableCell>{log.inventoryLog.itemName}</TableCell>
                  <TableCell>{log.inventoryLog.changes || "N/A"}</TableCell>
                  <TableCell>
                    {log.dateModified
                      ? dayjs(log.dateModified).format("YYYY-MM-DD HH:mm:ss")
                      : "N/A"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InventoryHistory;
