import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";

const PieChart = ({ title, data, options }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: 6,
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "50%" }, maxWidth: "600px" }}>
        <Typography variant="h5" align="center" gutterBottom>
          {title}
        </Typography>
        <Pie data={data} options={options} />
      </Box>
    </Box>
  );
};

export default PieChart;
