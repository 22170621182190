import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  MenuItem,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { saveImage } from "../../../demo/utilities/indexedDB";
import { getAuthCredentials } from "../../../utils/auth";
import SecureLS from "secure-ls";

import AddMenuCategoryDialog from "./AddMenuCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const CreateMenuItemDialog = ({
  open,
  onClose,
  onSave,
  ingredients,
  menuCategories,
  setMenuCategories,
  menuItems,
  fetchMenuCategories,
}) => {
  const [name, setName] = useState("");
  const [tags, setTags] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [category, setCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientQuantities, setIngredientQuantities] = useState({});
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [addons, setAddons] = useState([]);

  const { token } = getAuthCredentials();
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 5 * 1024 * 1024;

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setImageError(
        "Unsupported file type. Only JPEG, PNG, and GIF are allowed."
      );
      setImage(null);
      setImageFile(null);
      return;
    }

    if (file.size > maxFileSize) {
      setImageError("File size exceeds 5MB.");
      setImage(null);
      setImageFile(null);
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    setImage(previewUrl);
    setImageFile(file);
    setImageError("");
  };

  const handleAddMenuCategory = async (e) => {
    if (e.key === "Enter" && newCategory) {
      const user = ls.get("user");
      const posId = user?.posId;

      if (!token || !posId) {
        onClose();
        return;
      }

      const categoryExists = menuCategories.some(
        (cat) => cat.categoryName.toLowerCase() === newCategory.toLowerCase()
      );

      if (categoryExists) {
        const existingCategory = menuCategories.find(
          (cat) => cat.categoryName.toLowerCase() === newCategory.toLowerCase()
        );
        setCategory(existingCategory.categoryId);
        setNewCategory("");
        setIsAddingCategory(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/create-category`,
          { category: newCategory, posId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const updatedCategories = response.data.categories;

        if (Array.isArray(updatedCategories)) {
          const newCategoryEntry = updatedCategories.find(
            (cat) =>
              cat.categoryName.toLowerCase() === newCategory.toLowerCase()
          );

          if (newCategoryEntry) {
            setCategory(newCategoryEntry.categoryId);
          }

          setMenuCategories(updatedCategories);
        }

        setNewCategory("");
        setIsAddingCategory(false);
      } catch (error) {
        console.error("Error creating category:", error);
        alert(`Failed to create ${newCategory}. Please try again.`);
      }
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchMenuCategories();

      alert(`Category has been successfully deleted.`);
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Failed to delete category. Please try again.");
    }
  };

  const handleSave = async () => {
    if (!imageFile) {
      alert("Please upload an image.");
      return;
    }

    const newItem = {
      name,
      category,
      menuImage: imageFile,
      ingredients: selectedIngredients.map((ingredient) => ({
        ...ingredient,
        quantity: ingredientQuantities[ingredient.inventoryName] || 1,
      })),
      price,
      description,
      addons,
      status: "OptionsNotAdded",
      tags: tags.split(",").map((tag) => tag.trim()),
    };

    onSave(newItem);
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setName("");
    setCategory("");
    setImage(null);
    setSelectedIngredients([]);
    setPrice("");
    setDescription("");
    setAddons([]);
    setIngredientQuantities({});
  };

  const handleAddIngredient = (ingredient) => {
    if (
      !selectedIngredients.some((item) => item.inventoryId === ingredient._id)
    ) {
      setSelectedIngredients([
        ...selectedIngredients,
        { ...ingredient, inventoryId: ingredient._id },
      ]);
      setIngredientQuantities((prev) => ({
        ...prev,
        [ingredient.inventoryName]: 1,
      }));
    }
  };

  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(
      selectedIngredients.filter((item) => item.name !== ingredient.name)
    );
    const updatedQuantities = { ...ingredientQuantities };
    delete updatedQuantities[ingredient.name];
    setIngredientQuantities(updatedQuantities);
  };

  const handleQuantityChange = (ingredientName, value) => {
    const selectedIngredient = selectedIngredients.find(
      (ingredient) => ingredient.inventoryName === ingredientName
    );

    const maxQuantity = selectedIngredient?.instances[0]?.currentAmount || 0;
    const newQuantity = Math.min(parseInt(value) || 1, maxQuantity);

    setIngredientQuantities({
      ...ingredientQuantities,
      [ingredientName]: newQuantity,
    });
  };

  const calculateTotalIngredientPrice = () => {
    return selectedIngredients.reduce((total, ingredient) => {
      const quantity = ingredientQuantities[ingredient.inventoryName] || 1;
      const ingredientInstance = ingredient.instances[0];
      const ingredientPricePerUnit = ingredientInstance
        ? ingredientInstance.inventoryPrice / ingredientInstance.purchaseAmount
        : 0;

      return total + ingredientPricePerUnit * quantity;
    }, 0);
  };

  useEffect(() => {
    const totalPrice = calculateTotalIngredientPrice();
    setPrice(totalPrice);
  }, [selectedIngredients, ingredientQuantities]);

  const isFormValid =
    name && category && image && selectedIngredients.length > 0 && price;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category || ""}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              const selectedCategory = menuCategories.find(
                (cat) => cat.categoryId === selected
              );
              return selectedCategory ? selectedCategory.categoryName : "";
            }}
          >
            {menuCategories.map((cat) => (
              <MenuItem
                key={cat.categoryId}
                value={cat.categoryId}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat.categoryName}
                <Tooltip title={"Delete category"}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <AddMenuCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setMenuCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setIsAddingCategory(false);
            }}
          />
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>
          {image && (
            <Grid item>
              <img
                src={image}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>
        {imageError && (
          <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
            {imageError}
          </Typography>
        )}

        <TextField
          select
          label="Add New Ingredients"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => handleAddIngredient(JSON.parse(e.target.value))}
        >
          {ingredients.map((ingredient) => (
            <MenuItem key={ingredient._id} value={JSON.stringify(ingredient)}>
              {ingredient.inventoryName} -{" "}
              {ingredient.instances[0]?.currentAmount}{" "}
              {ingredient.unitOfMeasurement}
            </MenuItem>
          ))}
        </TextField>

        <List>
          {selectedIngredients.map((ingredient, index) => (
            <ListItem key={ingredient.inventoryId}>
              <ListItemText
                primary={`${ingredient.inventoryName} (${
                  ingredient.unitOfMeasurement || "UOM"
                })`}
              />
              <TextField
                label="Quantity"
                type="number"
                value={ingredientQuantities[ingredient.inventoryName] || 1}
                onChange={(e) =>
                  handleQuantityChange(
                    ingredient.inventoryName,
                    Number(e.target.value) || 1
                  )
                }
                size="small"
                sx={{ width: 80, marginRight: 1 }}
              />
              <IconButton
                edge="end"
                onClick={() => handleRemoveIngredient(ingredient)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <TextField
          label="Base Price"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          helperText={`Total Price of ingredients: ${calculateTotalIngredientPrice()}`}
        />

        <TextField
          label="Description (Optional)"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <TextField
          select
          label="Add-ons"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => setAddons([...addons, e.target.value])}
        >
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem._id} value={menuItem.name}>
              {menuItem.name}
            </MenuItem>
          ))}
        </TextField>

        <List>
          {addons.map((addon) => (
            <ListItem key={addon}>
              <ListItemText primary={addon} />
            </ListItem>
          ))}
        </List>

        <TextField
          label="Tags"
          fullWidth
          margin="normal"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          helperText="If more than one tag, separate with commas"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMenuItemDialog;
