import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import SecureLS from "secure-ls";
import { saveImage } from "../../../utilities/indexedDB";

const ls = new SecureLS({ encodingType: "aes" });

const CreateMenuItemDialog = ({ open, onClose, onSave }) => {
  const [name, setName] = useState("");
  const [tags, setTags] = useState("");
  const [menuCategories, setMenuCategories] = useState([]);
  const [isAddingMenuCategory, setIsAddingMenuCategory] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [addons, setAddons] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [ingredientQuantities, setIngredientQuantities] = useState({});

  useEffect(() => {
    const storedIngredients = ls.get("localDB_inventory")?.items || [];
    console.log("storedIngredients", storedIngredients);
    const storedMenuData = ls.get("localDB_menuItems") || {
      menu: [],
      categories: [],
    };
    console.log("storedMenuData", storedMenuData);
    const storedMenuItems = storedMenuData.menu || [];
    const storedMenuCategories = storedMenuData.categories || [];

    setMenuItems(storedMenuItems);
    setIngredients(storedIngredients);
    setMenuCategories(storedMenuCategories);
  }, []);

  useEffect(() => {
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImage(previewUrl);

      setImageFile(file);
    }
  };

  const handleAddMenuCategory = (e) => {
    if (e.key === "Enter" && newCategory) {
      const updatedCategories = [...menuCategories, newCategory];
      setMenuCategories(updatedCategories);
      setCategory(newCategory);
      setNewCategory("");
      setIsAddingMenuCategory(false);

      const storedMenuItems = ls.get("localDB_menuItems") || { categories: [] };
      storedMenuItems.categories = updatedCategories;
      ls.set("localDB_menuItems", storedMenuItems);
    }
  };

  const handleDeleteMenuCategory = (cat) => {
    const hasAssignedItems = menuItems.some((item) => item.category === cat);
    if (hasAssignedItems) {
      alert("Category has items");
    } else {
      const updatedCategories = menuCategories.filter(
        (category) => category !== cat
      );
      setMenuCategories(updatedCategories);

      if (category === cat) {
        setCategory("");
      }

      const storedMenuItems = ls.get("localDB_menuItems") || { categories: [] };
      storedMenuItems.categories = updatedCategories;
      ls.set("localDB_menuItems", storedMenuItems);
    }
  };

  const handleSave = async () => {
    const safeImageId = name.replace(/\s+/g, "_");

    if (imageFile) {
      console.log("Saving image as Blob:", imageFile);
      await saveImage(safeImageId, imageFile);
    } else {
      console.log("No image provided");
    }

    const newItem = {
      name,
      category,
      imageId: safeImageId,
      ingredients: selectedIngredients.map((ingredient) => ({
        ...ingredient,
      })),
      price,
      description,
      addons,
      status: "OptionsNotAdded",
      tags: tags.split(",").map((tag) => tag.trim()),
    };

    console.log("New item to be saved:", newItem);

    const storedMenuData = ls.get("localDB_menuItems") || {
      menu: [],
      categories: [],
    };

    const updatedMenuItems = Array.isArray(storedMenuData.menu)
      ? [...storedMenuData.menu, newItem]
      : [newItem];

    storedMenuData.menu = updatedMenuItems;
    ls.set("localDB_menuItems", storedMenuData);

    onSave(newItem);
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setName("");
    setCategory("");
    setImage(null);
    setSelectedIngredients([]);
    setPrice("");
    setDescription("");
    setAddons([]);
    setIngredientQuantities({});
  };

  const handleAddIngredient = (ingredient) => {
    if (!selectedIngredients.some((item) => item.name === ingredient.name)) {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };

  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(
      selectedIngredients.filter((item) => item.name !== ingredient.name)
    );
    const updatedQuantities = { ...ingredientQuantities };
    delete updatedQuantities[ingredient.name];
    setIngredientQuantities(updatedQuantities);
  };

  const handleQuantityChange = (ingredientName, value) => {
    setIngredientQuantities({
      ...ingredientQuantities,
      [ingredientName]: parseInt(value) || 1,
    });
  };

  const calculateTotalIngredientPrice = () => {
    return selectedIngredients.reduce((total, ingredient) => {
      const quantity = ingredientQuantities[ingredient.name] || 1;
      const ingredientPrice = ingredient.price / ingredient.amountOrdered; // Dividing total price by amount ordered
      return total + ingredientPrice * quantity;
    }, 0);
  };

  useEffect(() => {
    const totalPrice = calculateTotalIngredientPrice();
    setPrice(totalPrice);
  }, [selectedIngredients, ingredientQuantities]);

  const isFormValid =
    name && category && image && selectedIngredients.length > 0 && price;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => selected}
          >
            {menuCategories.map((cat, index) => (
              <MenuItem
                key={index}
                value={cat}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat}
                <Tooltip
                  title={
                    menuItems.some((item) => item.category === cat)
                      ? "Category has items"
                      : "Delete category"
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteMenuCategory(cat);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingMenuCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingMenuCategory && (
          <TextField
            margin="normal"
            label="New Category"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyDown={handleAddMenuCategory}
            helperText="Press Enter to add a new category"
          />
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>
          {image && (
            <Grid item>
              <img
                src={image}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>

        <TextField
          select
          label="Add Ingredients"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => handleAddIngredient(e.target.value)}
        >
          {ingredients.map((ingredient) => (
            <MenuItem key={ingredient.name} value={ingredient}>
              {ingredient.name} ({ingredient.price})
            </MenuItem>
          ))}
        </TextField>

        <List>
          {selectedIngredients.map((ingredient, index) => {
            const ingredientName = ingredient.name || "";
            return (
              <ListItem key={index}>
                <ListItemText primary={ingredientName} />
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveIngredient(ingredient)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>

        <TextField
          label="Base Price"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          helperText={`Total Price of ingredients: ${calculateTotalIngredientPrice()}`}
        />

        <TextField
          label="Description (Optional)"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <TextField
          select
          label="Add-ons"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => setAddons([...addons, e.target.value])}
        >
          {menuItems
            .filter((item) => item.name !== name)
            .map((menuItem) => (
              <MenuItem key={menuItem.name} value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            ))}
        </TextField>

        <List>
          {addons.map((addon) => (
            <ListItem key={addon}>
              <ListItemText primary={addon} />
            </ListItem>
          ))}
        </List>

        <TextField
          label="Tags"
          fullWidth
          margin="normal"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          helperText="If more than one tag, separate with commas"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMenuItemDialog;
