import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const UserModal = ({ open, onClose, roles, onUserAdded }) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    companyname: "",
    roleId: "",
  });

  const [error, setError] = useState({});
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const authToken = ls.get("authToken");
  const currentUser = ls.get("user");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "password") {
      setError((prev) => ({
        ...prev,
        passwordLength: value.length >= 8,
        passwordUppercase: /[A-Z]/.test(value),
        passwordLowercase: /[a-z]/.test(value),
        passwordNumber: /[0-9]/.test(value),
        passwordSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      }));
    }

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setError((prev) => ({
        ...prev,
        email: emailPattern.test(value)
          ? ""
          : "Please enter a valid email address.",
      }));
    }
  };

  const validateForm = () => {
    const newError = {};
    if (!formData.firstname) newError.firstname = "First name is required.";
    if (!formData.lastname) newError.lastname = "Last name is required.";
    if (!formData.email) newError.email = "Email is required.";
    if (!formData.password) newError.password = "Password is required.";
    if (!formData.roleId) newError.roleId = "Role is required.";

    setError((prev) => ({ ...prev, ...newError }));
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const payload = {
        ...formData,
        companyname: currentUser?.companyname || "", 
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/users`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "User Added",
        text: "The user has been added successfully.",
        confirmButtonText: "OK",
      });

      onUserAdded(); // Refresh users in the parent component
      onClose(); // Close the modal
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        companyname: "",
        roleId: "",
      }); // Reset the form
    } catch (error) {
      console.error("Error adding user:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to add user.",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <TextField
            label="First Name"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            error={!!error.firstname}
            helperText={error.firstname}
          />
          <TextField
            label="Last Name"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            error={!!error.lastname}
            helperText={error.lastname}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!error.email}
            helperText={error.email}
          />
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={handleChange}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
            error={!!error.password}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isPasswordFocused && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Password must include:
              </Typography>
              <Typography
                variant="body2"
                color={error.passwordLength ? "success.main" : "error.main"}
              >
                - At least 8 characters
              </Typography>
              <Typography
                variant="body2"
                color={error.passwordUppercase ? "success.main" : "error.main"}
              >
                - At least one uppercase letter
              </Typography>
              <Typography
                variant="body2"
                color={error.passwordLowercase ? "success.main" : "error.main"}
              >
                - At least one lowercase letter
              </Typography>
              <Typography
                variant="body2"
                color={error.passwordNumber ? "success.main" : "error.main"}
              >
                - At least one number
              </Typography>
              <Typography
                variant="body2"
                color={error.passwordSpecial ? "success.main" : "error.main"}
              >
                - At least one special character
              </Typography>
            </Box>
          )}
          <TextField
            label="Company Name"
            name="companyname"
            value={currentUser?.companyname || ""}
            disabled
            helperText="This is the current user's company name and cannot be changed."
          />
          <FormControl fullWidth error={!!error.roleId}>
            <InputLabel>Role</InputLabel>
            <Select
              name="roleId"
              value={formData.roleId}
              onChange={handleChange}
            >
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
            {error.roleId && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {error.roleId}
              </Typography>
            )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (validateForm()) {
              handleSubmit();
            }
          }}
          variant="contained"
          color="primary"
        >
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
