import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

export const mergeLocalDBInventory = (current, incoming) => {
  return {
    categories: mergeUniqueArrays(current.categories, incoming.categories),
    items: mergeArraysByKey(current.items, incoming.items, "id"),
    history: mergeArraysByKey(current.history, incoming.history, "id"),
  };
};

export const mergeLocalDBExpense = (current, incoming) => {
  return {
    expenses: mergeArraysByKey(current.expenses, incoming.expenses, "id"),
  };
};

export const mergeLocalDBMenuItems = (current, incoming) => {
  return {
    categories: mergeUniqueArrays(current.categories, incoming.categories),
    menu: mergeArraysByKey(current.menu, incoming.menu, "name"),
    tags: mergeTags(current.tags, incoming.tags),
  };
};

export const mergeLocalDBUsers = (current, incoming) => {
  return mergeArraysByKey(current, incoming, "email");
};

const mergeArraysByKey = (currentArray, incomingArray, key) => {
  const mergedArray = [...currentArray];

  incomingArray.forEach((incomingItem) => {
    const existingItemIndex = mergedArray.findIndex(
      (currentItem) => currentItem[key] === incomingItem[key]
    );

    if (existingItemIndex > -1) {
      mergedArray[existingItemIndex] = {
        ...mergedArray[existingItemIndex],
        ...incomingItem,
      };
    } else {
      mergedArray.push(incomingItem);
    }
  });

  return mergedArray;
};

const mergeUniqueArrays = (currentArray = [], incomingArray = []) => {
  return Array.from(new Set([...currentArray, ...incomingArray]));
};

const mergeTags = (currentTags = {}, incomingTags = {}) => {
  const mergedTags = { ...currentTags };

  Object.keys(incomingTags).forEach((tag) => {
    if (mergedTags[tag]) {
      mergedTags[tag].count += incomingTags[tag].count; 
      mergedTags[tag].status =
        mergedTags[tag].status || incomingTags[tag].status; 
    } else {
      mergedTags[tag] = incomingTags[tag];
    }
  });

  return mergedTags;
};
