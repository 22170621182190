import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  IconButton,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { getImage } from "../../utilities/indexedDB";

const MenuItems = ({
  menuItems,
  ingredients,
  onArchive,
  onEdit,
  onEditOptions,
  onEditTags, // Add onEditTags as a prop
}) => {
  const [menuWithImages, setMenuWithImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const updatedMenuItems = await Promise.all(
        menuItems.map(async (item) => {
          const safeImageId = item.imageId.replace(/\s+/g, "_");
          const imageBlob = safeImageId ? await getImage(safeImageId) : null;
          const imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null;

          return { ...item, image: imageUrl };
        })
      );

      const sortedMenuItems = updatedMenuItems.sort((a, b) =>
        a.category.localeCompare(b.category)
      );

      setMenuWithImages(sortedMenuItems);

      return () => {
        updatedMenuItems.forEach((item) => {
          if (item.image) {
            URL.revokeObjectURL(item.image);
          }
        });
      };
    };

    loadImages();
  }, [menuItems]);

  const findIngredient = (ingredientName) => {
    return ingredients.find((ingredient) => ingredient.name === ingredientName);
  };

  // Group menu items by category
  const categorizedMenuItems = menuWithImages.reduce((acc, item) => {
    const category = item.category || "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <Box>
      {Object.keys(categorizedMenuItems).map((category, categoryIndex) => (
        <Box key={categoryIndex} sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom>
            {category}
          </Typography>
          <Grid container spacing={3}>
            {categorizedMenuItems[category].map((item, index) => {
              const hasMissingIngredients = item.ingredients.some(
                (ingredient) => !findIngredient(ingredient.name)
              );
              const addSpacesToStatus = (status) => {
                return status.replace(/([a-z])([A-Z])/g, "$1 $2");
              };

              const status = addSpacesToStatus(
                item.status || "Options Not Added"
              );
              const statusColor =
                status === "Options Added" ? "#ADD8E6" : "#D3D3D3";

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: hasMissingIngredients
                        ? "#FFCCCB"
                        : "#ADD8E6",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      position: "relative",
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={() => onEdit(item)}
                  >
                    {/* Status Label */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: statusColor,
                        color: "#000",
                        padding: "4px 8px",
                        fontSize: "0.9em",
                        fontWeight: "bold",
                        borderRadius: "0 0 4px 0",
                      }}
                    >
                      {status}
                    </Box>

                    {item.image ? (
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt={item.name}
                        height="150"
                        sx={{ objectFit: "cover" }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No Image Available
                      </Typography>
                    )}
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Ingredients:
                      </Typography>
                      <ul>
                        {item.ingredients.map((ingredient, idx) => (
                          <li key={idx}>
                            {ingredient.name}{" "}
                            {!findIngredient(ingredient.name) && (
                              <span style={{ color: "red" }}> (missing)</span>
                            )}
                          </li>
                        ))}
                      </ul>
                      <Typography variant="body1">
                        Price: PHP {item.price}
                      </Typography>
                    </CardContent>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px",
                      }}
                    >
                      <Box>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditOptions(item);
                          }}
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ marginRight: 1 }}
                        >
                          Options
                        </Button>

                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditTags(item); 
                          }}
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          Tags
                        </Button>
                      </Box>

                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onArchive(item);
                        }}
                        color="secondary"
                        size="small"
                      >
                        <ArchiveIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default MenuItems;
