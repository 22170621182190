import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditOptionItems = ({
  open,
  onClose,
  onSave,
  menuItem,
  ingredients = [],
  menuItems = [],
}) => {
  const [optionGroups, setOptionGroups] = useState([
    {
      optionName: "",
      optionType: "single",
      choices: [
        {
          name: "",
          price: "",
          selectedType: "ingredient",
          ingredients: [{ ingredientName: "", amount: "" }],
          menuItem: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (menuItem?.options?.length > 0) {
      const existingOptions = menuItem.options.map((option) => ({
        optionName: option.optionName,
        optionType: option.optionType,
        choices: option.choices.map((choice) => ({
          name: choice.name,
          price: choice.price || "",
          selectedType: choice.menuItemReference ? "menuItem" : "ingredient",
          ingredients: choice.ingredientOptions
            ? choice.ingredientOptions.map((ingOpt) => ({
                ingredientName:
                  ingredients.find(
                    (ingredient) => ingredient._id === ingOpt.ingredientId
                  )?.inventoryName || "",
                amount: ingOpt.amount,
              }))
            : [{ ingredientName: "", amount: "" }],
          menuItem: choice.menuItemReference
            ? menuItems.find((item) => item._id === choice.menuItemReference)
                ?.menuName || ""
            : "",
        })),
      }));
      setOptionGroups(existingOptions);
    } else {
      setOptionGroups([
        {
          optionName: "",
          optionType: "single",
          choices: [
            {
              name: "",
              price: "",
              selectedType: "ingredient",
              ingredients: [{ ingredientName: "", amount: "" }],
              menuItem: "",
            },
          ],
        },
      ]);
    }
  }, [menuItem]);

  const handleAddChoice = (groupIndex) => {
    const updatedGroups = [...optionGroups];
    updatedGroups[groupIndex].choices.push({
      name: "",
      price: "",
      selectedType: "ingredient",
      ingredients: [{ ingredientName: "", amount: "" }],
      menuItem: "",
    });
    setOptionGroups(updatedGroups);
  };

  const handleRemoveChoice = (groupIndex, choiceIndex) => {
    const updatedGroups = [...optionGroups];
    updatedGroups[groupIndex].choices = updatedGroups[
      groupIndex
    ].choices.filter((_, i) => i !== choiceIndex);
    setOptionGroups(updatedGroups);
  };

  const handleAddOptionGroup = () => {
    setOptionGroups([
      ...optionGroups,
      {
        optionName: "",
        optionType: "single",
        choices: [
          {
            name: "",
            price: "",
            selectedType: "ingredient",
            ingredients: [{ ingredientName: "", amount: "" }],
            menuItem: "",
          },
        ],
      },
    ]);
  };

  const handleRemoveOptionGroup = (groupIndex) => {
    const updatedGroups = optionGroups.filter((_, i) => i !== groupIndex);
    setOptionGroups(updatedGroups);
  };

  const handleSaveOption = () => {
    const newOptions = optionGroups.map((group) => ({
      optionName: group.optionName,
      optionType: group.optionType,
      choices: group.choices.map((choice) => ({
        name: choice.name,
        price: choice.price || 0,
        ingredientOptions:
          choice.selectedType === "ingredient"
            ? choice.ingredients.map((ing) => ({
                ingredientId: ingredients.find(
                  (ingredient) =>
                    ingredient.inventoryName === ing.ingredientName
                )?._id,
                amount: ing.amount,
              }))
            : [],
        menuItemReference:
          choice.selectedType === "menuItem"
            ? menuItems.find((item) => item.menuName === choice.menuItem)?._id
            : null,
      })),
    }));

    const updatedMenuItem = { ...menuItem, options: newOptions };
    onSave(updatedMenuItem);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Options</DialogTitle>
      <DialogContent>
        {optionGroups.map((group, groupIndex) => (
          <Accordion key={groupIndex} defaultExpanded={groupIndex === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${groupIndex}-content`}
              id={`panel${groupIndex}-header`}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Typography>
                  {group.optionName || `Option Group ${groupIndex + 1}`}
                </Typography>
              </Box>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveOptionGroup(groupIndex);
                }}
                sx={{
                  color: "#f44336",
                  borderColor: "#f44336",
                  "&:hover": {
                    backgroundColor: "#f4433620",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Option Name"
                fullWidth
                margin="normal"
                value={group.optionName}
                onChange={(e) => {
                  const updatedGroups = [...optionGroups];
                  updatedGroups[groupIndex].optionName = e.target.value;
                  setOptionGroups(updatedGroups);
                }}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Option Type</InputLabel>
                <Select
                  value={group.optionType}
                  onChange={(e) => {
                    const updatedGroups = [...optionGroups];
                    updatedGroups[groupIndex].optionType = e.target.value;
                    setOptionGroups(updatedGroups);
                  }}
                >
                  <MenuItem value="single">Single Select (Radio)</MenuItem>
                  <MenuItem value="checkbox">
                    Multiple Select (Checkbox)
                  </MenuItem>
                </Select>
              </FormControl>

              {group.choices.map((choice, choiceIndex) => (
                <Accordion
                  key={choiceIndex}
                  defaultExpanded={false}
                  sx={{ marginBottom: 2 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${groupIndex}-choice${choiceIndex}-content`}
                    id={`panel${groupIndex}-choice${choiceIndex}-header`}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Typography>
                        {choice.name || `Choice ${choiceIndex + 1}`}
                      </Typography>
                    </Box>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveChoice(groupIndex, choiceIndex);
                      }}
                      sx={{
                        color: "#f44336",
                        borderColor: "#f44336",
                        "&:hover": {
                          backgroundColor: "#f4433620",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          label="Choice Name"
                          fullWidth
                          value={choice.name}
                          onChange={(e) => {
                            const updatedGroups = [...optionGroups];
                            updatedGroups[groupIndex].choices[
                              choiceIndex
                            ].name = e.target.value;
                            setOptionGroups(updatedGroups);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Price"
                          fullWidth
                          value={choice.price}
                          onChange={(e) => {
                            const updatedGroups = [...optionGroups];
                            updatedGroups[groupIndex].choices[
                              choiceIndex
                            ].price = e.target.value;
                            setOptionGroups(updatedGroups);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={choice.selectedType}
                            onChange={(e) => {
                              const updatedGroups = [...optionGroups];
                              updatedGroups[groupIndex].choices[
                                choiceIndex
                              ].selectedType = e.target.value;
                              if (e.target.value === "ingredient") {
                                updatedGroups[groupIndex].choices[
                                  choiceIndex
                                ].ingredients = [
                                  { ingredientName: "", amount: "" },
                                ];
                                updatedGroups[groupIndex].choices[
                                  choiceIndex
                                ].menuItem = "";
                              } else {
                                updatedGroups[groupIndex].choices[
                                  choiceIndex
                                ].ingredients = [];
                                updatedGroups[groupIndex].choices[
                                  choiceIndex
                                ].menuItem = "";
                              }
                              setOptionGroups(updatedGroups);
                            }}
                          >
                            <MenuItem value="ingredient">Ingredient</MenuItem>
                            <MenuItem value="menuItem">Menu Item</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {choice.selectedType === "ingredient" && (
                        <>
                          {choice.ingredients.map(
                            (ingredientEntry, ingredientIndex) => (
                              <Grid container spacing={2} key={ingredientIndex}>
                                <Grid item xs={5}>
                                  <FormControl fullWidth>
                                    <InputLabel>Select Ingredient</InputLabel>
                                    <Select
                                      value={ingredientEntry.ingredientName}
                                      onChange={(e) => {
                                        const updatedGroups = [...optionGroups];
                                        updatedGroups[groupIndex].choices[
                                          choiceIndex
                                        ].ingredients[
                                          ingredientIndex
                                        ].ingredientName = e.target.value;
                                        setOptionGroups(updatedGroups);
                                      }}
                                    >
                                      {ingredients.map((ingredient) => (
                                        <MenuItem
                                          key={ingredient._id}
                                          value={ingredient.inventoryName}
                                        >
                                          {ingredient.inventoryName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    label="Ingredient Amount"
                                    fullWidth
                                    value={ingredientEntry.amount || ""}
                                    onChange={(e) => {
                                      const updatedGroups = [...optionGroups];
                                      updatedGroups[groupIndex].choices[
                                        choiceIndex
                                      ].ingredients[ingredientIndex].amount =
                                        e.target.value;
                                      setOptionGroups(updatedGroups);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton
                                    color="error"
                                    onClick={() => {
                                      const updatedGroups = [...optionGroups];
                                      updatedGroups[groupIndex].choices[
                                        choiceIndex
                                      ].ingredients.splice(ingredientIndex, 1);
                                      setOptionGroups(updatedGroups);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )
                          )}
                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              const updatedGroups = [...optionGroups];
                              updatedGroups[groupIndex].choices[
                                choiceIndex
                              ].ingredients.push({
                                ingredientName: "",
                                amount: "",
                              });
                              setOptionGroups(updatedGroups);
                            }}
                          >
                            Add Ingredient
                          </Button>
                        </>
                      )}

                      {choice.selectedType === "menuItem" && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>Select Menu Item</InputLabel>
                            <Select
                              value={choice.menuItem}
                              onChange={(e) => {
                                const updatedGroups = [...optionGroups];
                                updatedGroups[groupIndex].choices[
                                  choiceIndex
                                ].menuItem = e.target.value;
                                setOptionGroups(updatedGroups);
                              }}
                            >
                              {menuItems.map((item) => (
                                <MenuItem key={item._id} value={item.menuName}>
                                  {item.menuName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddChoice(groupIndex)}
                  sx={{ marginRight: 2 }}
                >
                  Add Choice
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddOptionGroup}
          sx={{ marginTop: 2 }}
        >
          Add Option Group
        </Button>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveOption} color="primary">
          Save Options
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOptionItems;
