import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import axios from "axios";
import InventoryHero from "./InventoryHero";
import { getAuthCredentials } from "../../utils/auth";

const InventoryArchive = () => {
  const [archivedInventory, setArchivedInventory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("expiryDate");
  const { token } = getAuthCredentials();

  useEffect(() => {
    const fetchArchivedInventory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/archive`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const archives =
          response.data.archivedInventory?.flatMap(
            (archive) => archive.inventoryArchive
          ) || [];
        setArchivedInventory(archives);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setArchivedInventory([]);
          Swal.fire({
            icon: "info",
            title: "No Archived Items",
            text: error.response.data.message,
          });
        } else {
          console.error("Error fetching archived inventory:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to fetch archived inventory.",
          });
        }
      }
    };

    fetchArchivedInventory();
  }, [token]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortInventory = (array, comparator) => {
    return array.sort((a, b) => comparator(a, b));
  };

  const getComparator = (order, orderBy) => {
    return order === "asc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "expiryDate") {
      const latestExpiryA = a.instances.reduce(
        (latest, instance) =>
          instance.expiryDate &&
          (!latest || dayjs(instance.expiryDate).isAfter(latest))
            ? instance.expiryDate
            : latest,
        null
      );
      const latestExpiryB = b.instances.reduce(
        (latest, instance) =>
          instance.expiryDate &&
          (!latest || dayjs(instance.expiryDate).isAfter(latest))
            ? instance.expiryDate
            : latest,
        null
      );

      if (!latestExpiryA && !latestExpiryB) return 0;
      if (!latestExpiryA) return -1;
      if (!latestExpiryB) return 1;

      return dayjs(latestExpiryA).isBefore(dayjs(latestExpiryB)) ? -1 : 1;
    }
    return 0;
  };

  const handleRestore = async (item) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/unarchive`,
        { inventoryId: item.inventoryId, instanceIndex: item.instanceIndex }, // Include instanceIndex
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Instance from ${item.inventoryName} unarchived successfully.`,
      });

      setArchivedInventory((prevInventory) =>
        prevInventory.filter(
          (archivedItem) =>
            archivedItem.inventoryId !== item.inventoryId ||
            archivedItem.instanceIndex !== item.instanceIndex
        )
      );
    } catch (error) {
      console.error("Error unarchiving instance:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to unarchive the instance from ${item.inventoryName}. Please try again.`,
      });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Typography variant="h5" gutterBottom>
        Archived Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={orderBy === "inventoryName" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "inventoryName"}
                  direction={orderBy === "inventoryName" ? order : "asc"}
                  onClick={() => handleSort("inventoryName")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Amount Ordered</TableCell>
              <TableCell>Current Amount</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Delivery Date</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortInventory(
              archivedInventory,
              getComparator(order, orderBy)
            ).map((item, index) => {
              const latestInstance = item.instances.reduce(
                (latest, instance) =>
                  instance.expiryDate &&
                  (!latest ||
                    dayjs(instance.expiryDate).isAfter(
                      dayjs(latest.expiryDate)
                    ))
                    ? instance
                    : latest,
                null
              );

              return (
                <TableRow key={index}>
                  <TableCell>{item.inventoryName}</TableCell>
                  <TableCell>
                    {latestInstance?.purchaseAmount || "N/A"}
                  </TableCell>
                  <TableCell>
                    {latestInstance?.currentAmount || "N/A"}
                  </TableCell>
                  <TableCell>
                    {latestInstance?.inventoryPrice || "N/A"}
                  </TableCell>
                  <TableCell>
                    {latestInstance?.deliveryDate
                      ? dayjs(latestInstance.deliveryDate).format("YYYY-MM-DD")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {latestInstance?.expiryDate
                      ? dayjs(latestInstance.expiryDate).format("YYYY-MM-DD")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleRestore(item)}
                    >
                      Restore
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InventoryArchive;
