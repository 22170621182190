import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const AddMenuCategoryDialog = ({ open, onClose, onCategoryAdded }) => {
  const [newCategory, setNewCategory] = useState("");

  const handleAddCategory = async () => {
    const user = ls.get("user");
    const token = ls.get("authToken");
    const posId = user?.posId;

    if (!token || !posId || !newCategory.trim()) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/create-category`,
        { category: newCategory, posId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedCategories = response.data.categories;

      if (Array.isArray(updatedCategories)) {
        const addedCategory = updatedCategories.find(
          (cat) => cat.categoryName.toLowerCase() === newCategory.toLowerCase()
        );

        if (addedCategory) {
          onCategoryAdded(addedCategory);
        }
      }

      setNewCategory("");
      onClose();
    } catch (error) {
      console.error("Error creating category:", error);
      alert(`Failed to create ${newCategory}. Please try again.`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add New Menu Category</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Category Name"
          fullWidth
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAddCategory}
          color="primary"
          disabled={!newCategory.trim()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMenuCategoryDialog;
