import axios from "axios";
import Swal from "sweetalert2";

const handleRestockInventory = async (
  inventoryId,
  restockData,
  token,
  fetchInventory
) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/replenish`,
      { inventoryId, ...restockData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    Swal.fire({
      title: "Restocked!",
      text: "Inventory has been successfully replenished.",
      icon: "success",
      confirmButtonText: "OK",
    });

    await fetchInventory();
  } catch (error) {
    console.error("Error restocking inventory:", error);

    Swal.fire({
      title: "Error",
      text: "Failed to replenish the inventory. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

export default handleRestockInventory;
