import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Button, Paper } from "@mui/material";
import {
  BarChart,
  Inventory,
  Category,
  Lock,
  Person,
  AccountCircle,
  Folder,
  ReceiptLong,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";

const ls = new SecureLS({ encodingType: "aes" });

const modulesList = [
  { title: "Sales Reports", icon: BarChart, path: "/sales" },
  { title: "Inventory Management", icon: Inventory, path: "/inventory" },
  { title: "Menu Items Creation", icon: Category, path: "/menucreation" },
  { title: "Access", icon: Lock, path: "/access-management" },
  { title: "Account Management", icon: Person, path: "/account-management" },
  { title: "Reports", icon: Folder, path: "/reports" },
  {
    title: "Receipt Configuration",
    icon: ReceiptLong,
    path: "/receipt-config",
  },
];

const DashboardCard = ({ title, icon: Icon, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        width: "100%",
        padding: 4,
        textAlign: "center",
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        height: "100%",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Icon sx={{ fontSize: 60, marginBottom: 2 }} />
        <Typography variant="h6">{title}</Typography>
      </Paper>
    </Button>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const user = ls.get("user");
  const companyName = user?.companyname || "POS Client";
  const userRole = user?.role || "Guest";
  const userModules = user?.modules || [];

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out and redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        ls.clear();
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/login";
        });
      }
    });
  };

  // Filter modules based on role
  const availableModules =
    userRole === "Superadmin"
      ? modulesList
      : modulesList.filter(
          (module) =>
            userModules.includes(module.title.replace(/ /g, "")) ||
            module.title === "Account Management"
        );

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        mb={4}
        sx={{ gap: 2 }}
      >
        <AccountCircle sx={{ fontSize: 100 }} />
        <Typography variant="h4" textAlign="center">
          {companyName}'s Dashboard
        </Typography>
      </Box>
      <Box
        sx={{
          position: { xs: "relative", sm: "absolute" },
          top: { xs: "auto", sm: 10 },
          right: { xs: "auto", sm: 10 },
          mt: { xs: 2, sm: 0 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {availableModules.map((module) => (
          <Grid
            key={module.title}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: "flex" }}
          >
            <DashboardCard
              title={module.title}
              icon={module.icon}
              onClick={() => navigate(module.path)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
