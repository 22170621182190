import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AccessHero from "./AccessHero";
import RolesModal from "./Modal/RolesModal";
import UserModal from "./Modal/UserModal";
import EditUserModal from "./Modal/EditUserModal";
import {
  fetchUsers,
  fetchRoles,
  deleteUser,
  deleteRole,
  addRole,
  editRole,
} from "./AccessFunctions";

const AccessManagement = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleFetchUsers = async () => {
    const usersData = await fetchUsers();
    setUsers(usersData);
  };

  const handleFetchRoles = async () => {
    const rolesData = await fetchRoles();
    setRoles(rolesData);
  };

  const handleAddRole = async (roleData) => {
    await addRole(roleData, handleFetchRoles);
  };

  const handleEditRole = async (roleId, updatedRoleData) => {
    setIsRolesModalOpen(false);
    await editRole(roleId, updatedRoleData, handleFetchRoles);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    await deleteUser(userId, handleFetchUsers);
  };

  const handleDeleteRole = async (roleId) => {
    setIsRolesModalOpen(false);
    await deleteRole(roleId, handleFetchRoles);
    setIsRolesModalOpen(true);
  };

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([handleFetchUsers(), handleFetchRoles()]);
    };
    initializeData();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <AccessHero />

      <Box sx={{ mt: -3, display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsRolesModalOpen(true)}
        >
          Roles
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsUserModalOpen(true)}
        >
          Add User
        </Button>
      </Box>

      <Divider sx={{ marginY: 4 }} />

      {/* Users Table */}
      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Modules</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.firstname}</TableCell>
                  <TableCell>{user.lastname}</TableCell>
                  <TableCell>{user.role || "No Role Assigned"}</TableCell>
                  <TableCell>
                    {user.role === "Superadmin" ? (
                      <Box
                        sx={{
                          display: "inline-block",
                          padding: "2px 8px",
                          borderRadius: "16px",
                          backgroundColor: "lightgreen",
                          color: "#00695c",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        All
                      </Box>
                    ) : user.roleId?.modules?.length ? (
                      user.roleId.modules.map((module) => (
                        <Box
                          key={module}
                          sx={{
                            display: "inline-block",
                            padding: "2px 8px",
                            borderRadius: "16px",
                            backgroundColor: "lightblue",
                            color: "#1e88e5",
                            fontSize: "12px",
                            fontWeight: "bold",
                            margin: "2px",
                          }}
                        >
                          {module.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </Box>
                      ))
                    ) : (
                      "No Modules Assigned"
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ marginRight: 1 }}
                      onClick={() => handleEditUser(user)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDeleteUser(user._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <RolesModal
        open={isRolesModalOpen}
        onClose={() => setIsRolesModalOpen(false)}
        roles={roles}
        onEditRole={(roleId, updatedRoleData) =>
          handleEditRole(roleId, updatedRoleData)
        }
        onDeleteRole={handleDeleteRole}
        onRoleAdded={handleAddRole}
      />
      <UserModal
        open={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        roles={roles}
        onUserAdded={handleFetchUsers}
      />
      <EditUserModal
        open={isEditUserModalOpen}
        onClose={() => setIsEditUserModalOpen(false)}
        roles={roles}
        user={selectedUser}
        onUserUpdated={handleFetchUsers}
      />
    </Box>
  );
};

export default AccessManagement;
