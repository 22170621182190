import jsPDF from 'jspdf';

const generateZReadingTextFile = (receiptConfig, zReading) => {
  let textContent = '';

  // Add Header
  textContent += `${receiptConfig.companyName || "N/A"}\n`;
  textContent += `Owned and Operated By:\n`;
  textContent += `${receiptConfig.storeName || "N/A"}\n`;
  textContent += `${receiptConfig.address || "N/A"}\n`;
  textContent += `Vat Registered TIN: ${receiptConfig.TIN || "N/A"}\n`;
  textContent += `SN: ${receiptConfig.SN || "N/A"} MIN: ${receiptConfig.MIN || "N/A"}\n`;
  textContent += "========================================\n";

  // Z-Reading Details
  textContent += `----------------------------------------\n`;
  textContent += `Reset Counter No.: Non-Resettable\n`;
  textContent += `Day-end Report Z-Reading#: ${zReading._id || "N/A"}\n`;
  textContent += `Administrator: ${zReading.administrator || "N/A"}\n`;
  textContent += `Cashier: AUTO-GENERATED\n`;
  textContent += `Log Date: ${zReading.date ? new Date(zReading.date).toLocaleDateString() : "N/A"}\n`;
  textContent += `Date/Time: ${zReading.createdAt ? new Date(zReading.createdAt).toLocaleString() : "N/A"}\n`;
  textContent += `Terminal Code: 001\n`;
  textContent += `Guest Count: ${zReading.totalTransactions || 0}\n`;
  textContent += `----------------------------------------\n`;

  // Sales Summary
  textContent += `            SALES SUMMARY REPORT\n`;
  textContent += `Gross Sales:          ${String(zReading.totalTransactions || 0).padEnd(5)}    ${String(zReading.grossSales?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `Senior Discounts:     ${String(zReading.seniorTransactionCount || 0).padEnd(5)}   -${String(zReading.seniorDiscounts?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `SC VAT Deduction:     ${String(zReading.seniorTransactionCount || 0).padEnd(5)}   -${String(zReading.scVatDeduction?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `PWD Discounts:        ${String(zReading.pwdTransactionCount || 0).padEnd(5)}   -${String(zReading.pwdDiscounts?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `PWD VAT Deduction:    ${String(zReading.pwdTransactionCount || 0).padEnd(5)}   -${String(zReading.pwdVatDeduction?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `Athlete Discounts:    ${String(zReading.athleteTransactionCount || 0).padEnd(5)}   -${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(7)}\n`;
  textContent += `----------------------------------------\n`;
  textContent += `Sub-Total:                  ${String(zReading.subTotal?.toFixed(2) || "0.00").padStart(10)}\n`;
  textContent += `----------------------------------------\n`;
  textContent += `Net Total:                  ${String(zReading.netTotal?.toFixed(2) || "0.00").padStart(10)}\n`;
  textContent += `----------------------------------------\n`;

  // VAT Summary
  textContent += `      TAX DECLARATION SUMMARY REPORT\n`;
  textContent += `VATable Sales:                  ${zReading.vatSales?.toFixed(2) || "0.00"}\n`;
  textContent += `VAT Amount:                      ${zReading.vatAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `VAT Exempt Sales:               ${zReading.vatExemptSales?.toFixed(2) || "0.00"}\n`;
  textContent += `Net of VAT Exempt:              ${zReading.netOfVatExempt?.toFixed(2) || "0.00"}\n`;
  textContent += `Zero-Rated Sales:                 ${zReading.zeroRatedSales?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Mandated VAT & Discount Report
  textContent += `     MANDATED VAT & DISCOUNT REPORT\n`;
  textContent += `SC/PWD/Diplomat TRN Count:        ${zReading.scPwdDiplomatTransactionCount || 0}\n`;
  textContent += `Senior Discount:                  ${zReading.seniorDiscounts?.toFixed(2) || "0.00"}\n`;
  textContent += `SC VAT Deduction:                 ${zReading.scVatDeduction?.toFixed(2) || "0.00"}\n`;
  textContent += `PWD Discount:                   ${zReading.pwdDiscounts?.toFixed(2) || "0.00"}\n`;
  textContent += `PWD VAT Deduction:               ${zReading.pwdVatDeduction?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Transaction Details
  textContent += `          TRANSACTION DETAILS\n`;
  textContent += `Beginning Inv. No.:               ${zReading.beginningInvoiceNo || "N/A"}\n`;
  textContent += `Ending Inv. No.:                  ${zReading.endingInvoiceNo || "N/A"}\n`;
  textContent += `Total:                            ${zReading.totalInvoiceCount || 0}\n`;
  textContent += `Open Drawer Count:                ${zReading.openDrawerCount || 0}\n`;
  textContent += `Void Items:              ${zReading.voidItemsCount || 0}        ${zReading.voidItemsAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `Refunds:                 ${zReading.refundsCount || 0}        ${zReading.refundsAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Tender Summary
  textContent += `          TENDER SUMMARY REPORT\n`;
  zReading.tenderSummary?.forEach(tender => {
    textContent += `${tender.type.padEnd(20)} ${tender.count || 0} ${tender.amount?.toFixed(2) || "0.00"}\n`;
  });
  textContent += `Total Tenders:           ${zReading.totalTendersCount || 0}        ${zReading.totalTendersAmount?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Cash Breakdown
  textContent += `           CASH BREAKDOWN REPORT\n`;
  zReading.cashBreakdown?.forEach(denomination => {
    textContent += `${denomination.type.padEnd(20)} ${denomination.count || 0} ${denomination.amount?.toFixed(2) || "0.00"}\n`;
  });
  textContent += `        TOTAL:                    ${zReading.totalCashBreakdown?.toFixed(2) || "0.00"}\n`;
  textContent += `   SHORT/OVER:                    ${zReading.shortOver?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Sales per Cashier
  textContent += `     SALES PER CASHIER SUMMARY REPORT\n`;
  zReading.cashierSalesSummary?.forEach(cashier => {
    textContent += `${cashier.name.padEnd(20)} ${cashier.amount?.toFixed(2) || "0.00"}\n`;
  });
  textContent += `Total Cashier Sales:              ${zReading.totalCashierSales?.toFixed(2) || "0.00"}\n`;
  textContent += `----------------------------------------\n`;

  // Hourly Sales Summary
  textContent += `      HOURLY SALES SUMMARY REPORT\n`;
  textContent += `----------------------------------------\n`;
  textContent += `HOUR  TRN. COUNT  QTY. SOLD       AMOUNT\n`;

  let totalTransactions = 0;
  let totalQuantity = 0;
  let totalAmount = 0;

  zReading.hourlySales?.forEach((hourData) => {
    if (hourData.transactionCount === 0 && hourData.quantitySold === 0 && hourData.amount === 0) {
      return; // Skip if no sales
    }

    const formattedAmount = hourData.amount?.toFixed(2) || "0.00";
    textContent += `${hourData.hour.padEnd(6)}  ${hourData.transactionCount.toString().padStart(3)}       ${hourData.quantitySold.toString().padStart(5)}     ${formattedAmount.padStart(10)}\n`;

    totalTransactions += hourData.transactionCount || 0;
    totalQuantity += hourData.quantitySold || 0;
    totalAmount += hourData.amount || 0;
  });

  // Add the totals row
  textContent += `----------------------------------------\n`;
  textContent += `Total:  ${totalTransactions.toString().padStart(3)}       ${totalQuantity.toString().padStart(5)}     ${totalAmount.toFixed(2).padStart(10)}\n`;

  // Footer
  textContent += `========================================\n`;
  textContent += `POS PROVIDER: SparkleStar International Corporation\n`;

  // Create a Blob and trigger the download of the text file
  const blob = new Blob([textContent], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'zReadingReport.txt';
  link.click();

  const generatePDFFromText = (textContent) => {
      const doc = new jsPDF();
      const pageHeight = 280;
      const margin = 10;
      let yOffset = margin;
    
      const lines = textContent.split('\n'); 
    
      lines.forEach(line => {
        const lineHeight = line.length > 60 ? 12 : 10; 
    
        if (yOffset + lineHeight > pageHeight - margin) { 
          doc.addPage();
          yOffset = margin;
        }
    
        doc.text(line, 10, yOffset);
        yOffset += lineHeight; 
      });
    
      const pdfBlob = doc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      window.open(pdfUrl, '_blank'); 
    };
    
    generatePDFFromText(textContent);
  };

export default generateZReadingTextFile;
