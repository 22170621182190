import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import AccountHero from "./AccountHero"; 

const AccountManagement = () => {
  const email = "user@example.com"; 
  const role = "Administrator"; 

  return (
    <Box sx={{ padding: 3 }}>
      {/* Use the AccountHero component with a title */}
      <AccountHero title="Account Management" />

      {/* Account Information Section */}
      <Grid container spacing={4}>
        {/* Email Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Email</Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* Role Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Role</Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <TextField
            label="Role"
            variant="outlined"
            fullWidth
            value={role}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* Change Password Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Password</Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Button variant="contained" color="primary">
            Change Password
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountManagement;
