import axios from "axios";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

// Fetch Users
export const fetchUsers = async () => {
  try {
    const authToken = ls.get("authToken");
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/users`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data.users || [];
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

// Fetch Roles
export const fetchRoles = async () => {
  try {
    const authToken = ls.get("authToken");
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/roles`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data.roles || [];
  } catch (error) {
    console.error("Error fetching roles:", error);
    return [];
  }
};

// Add User
export const addUser = async (userData, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/users`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    Swal.fire("Success", "User created successfully.", "success");
    refreshCallback();
    return response.data.newUser;
  } catch (error) {
    console.error("Error adding user:", error);
    Swal.fire(
      "Error",
      error.response?.data?.message || "Failed to add user.",
      "error"
    );
  }
};

// Add Role
export const addRole = async (roleData, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/roles`,
      roleData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    Swal.fire("Success", "Role created successfully.", "success");
    refreshCallback();
    return response.data.newRole;
  } catch (error) {
    console.error("Error adding role:", error);
    Swal.fire(
      "Error",
      error.response?.data?.message || "Failed to add role.",
      "error"
    );
  }
};

// Edit User
export const editUser = async (userId, userData, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/users/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    Swal.fire("Success", "User updated successfully.", "success");
    refreshCallback();
    return response.data.updatedUser;
  } catch (error) {
    console.error("Error editing user:", error);
    Swal.fire(
      "Error",
      error.response?.data?.message || "Failed to edit user.",
      "error"
    );
  }
};

// Edit Role
export const editRole = async (roleId, roleData, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    if (!roleId) {
      throw new Error("Role ID is missing in the provided data.");
    }
    console.log("roleId in Edit Function: ", roleId);

    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/roles/${roleId}`,
      roleData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    Swal.fire("Success", "Role updated successfully.", "success");
    refreshCallback();
    return response.data.updatedRole;
  } catch (error) {
    console.error("Error editing role:", error);
    Swal.fire(
      "Error",
      error.response?.data?.message || "Failed to edit role.",
      "error"
    );
  }
};

// Delete User
export const deleteUser = async (userId, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirm.isConfirmed) {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      Swal.fire("Deleted!", "The user has been deleted.", "success");
      refreshCallback();
    }
  } catch (error) {
    console.error("Error deleting user:", error);
    Swal.fire("Error", "Failed to delete user.", "error");
  }
};

// Delete Role
export const deleteRole = async (roleId, refreshCallback) => {
  try {
    const authToken = ls.get("authToken");
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      stopKeydownPropagation: true,
    });

    if (confirm.isConfirmed) {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/access/roles/${roleId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      Swal.fire("Deleted!", "The role has been deleted.", "success");
      refreshCallback();
    }
  } catch (error) {
    console.error("Error deleting role:", error);
    Swal.fire("Error", "Failed to delete role.", "error");
  }
};
