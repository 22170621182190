import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import SecureLS from "secure-ls";
import axios from "axios";
import Swal from "sweetalert2";
import AccountHero from "./AccountHero";
import ChangePasswordDialog from "./ChangePasswordDialog";

const ls = new SecureLS({ encodingType: "aes" });

const AccountManagement = () => {
  const user = ls.get("user");
  const email = user?.email || "No Email Available";
  const firstname = user?.firstname || "No First Name Available";
  const lastname = user?.lastname || "No Last Name Available";
  const companyname = user?.companyname || "No Company Name Available";
  const role = user?.role || "No Role Available";

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [backendError, setBackendError] = useState("");

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (clearFields = false) => {
    if (clearFields) {
      setBackendError("");
    }
    setIsDialogOpen(false);
  };

  const handleChangePassword = async (currentPassword, newPassword) => {
    const authToken = ls.get("authToken");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setBackendError("");
        setIsDialogOpen(false);
        Swal.fire({
          icon: "success",
          title: "Password Changed",
          text: "Your password has been changed successfully!",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      setBackendError(
        error.response?.data?.message || "Failed to change password."
      );
      setIsDialogOpen(true);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <AccountHero title="Account Management" />

      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6">First Name</Typography>
              </TableCell>
              <TableCell>{firstname}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6">Last Name</Typography>
              </TableCell>
              <TableCell>{lastname}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6">Company Name</Typography>
              </TableCell>
              <TableCell>{companyname}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6">Role</Typography>
              </TableCell>
              <TableCell>{role}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ marginY: 4 }} />

      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Change Password
        </Button>
      </Box>

      <ChangePasswordDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onChangePassword={(currentPassword, newPassword) =>
          handleChangePassword(currentPassword, newPassword)
        }
        backendError={backendError}
      />
    </Box>
  );
};

export default AccountManagement;
