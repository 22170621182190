import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const EditTagsItems = ({ open, onClose, menuItem, menuTags, onSave }) => {
  const [tags, setTags] = useState(menuTags || []);
  const [newTag, setNewTag] = useState("");
  const { token } = getAuthCredentials();

  useEffect(() => {
    setTags(menuTags || []);
  }, [menuTags]);

  const handleAddTag = async () => {
    if (newTag && !tags.includes(newTag)) {
      try {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/add-tag/${menuItem._id}`,
          { tag: newTag },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTags([...tags, newTag]);
        setNewTag("");
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    }
  };

  const handleDeleteTag = async (tagToDelete) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-tag/${menuItem._id}`,
        { tag: tagToDelete },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTags(tags.filter((tag) => tag !== tagToDelete));
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleSave = () => {
    const updatedItem = { ...menuItem, menuTags: tags };
    onSave(updatedItem);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Tags</DialogTitle>
      <DialogContent>
        {tags.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                deleteIcon={<DeleteIcon />}
                color="primary"
              />
            ))}
          </Box>
        ) : (
          <Typography>No Tags Assigned</Typography>
        )}

        <Box sx={{ display: "flex", marginTop: 2 }}>
          <TextField
            label="New Tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            sx={{ width: "80%" }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={handleAddTag}
            sx={{ marginLeft: 1, width: "20%" }}
          >
            Add Tag
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTagsItems;
