import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Grid,
  Divider,
} from "@mui/material";
import SecureLS from "secure-ls";
import { getImage } from "../../utilities/indexedDB";

const ls = new SecureLS({ encodingType: "aes" });

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const MenuPreview = ({ menuItems }) => {
  const [tags, setTags] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuWithImages, setMenuWithImages] = useState([]);

  useEffect(() => {
    const tagCount = {};

    menuItems.forEach((item) => {
      item.tags.forEach((tag) => {
        const tagLowerCase = tag.toLowerCase();
        if (!tagCount[tagLowerCase]) {
          tagCount[tagLowerCase] = { count: 1, status: true };
        } else {
          tagCount[tagLowerCase].count += 1;
        }
      });
    });

    const storedMenuData = ls.get("localDB_menuItems") || {
      tags: {},
      toggles: {},
    };
    const storedTags = storedMenuData.tags || {};
    const storedToggles = storedMenuData.toggles || {};

    Object.keys(storedToggles).forEach((toggleTag) => {
      if (!tagCount[toggleTag]) {
        delete storedToggles[toggleTag];
      }
    });

    Object.keys(tagCount).forEach((tagName) => {
      if (storedToggles[tagName] !== undefined) {
        tagCount[tagName].status = storedToggles[tagName];
      } else {
        storedToggles[tagName] = true;
      }
    });

    setTags(tagCount);
    storedMenuData.toggles = storedToggles;
    ls.set("localDB_menuItems", storedMenuData);

    // Automatically select the first active tag on mount
    const firstActiveTag = Object.keys(tagCount).find(
      (tagName) => tagCount[tagName].status
    );

    if (firstActiveTag) {
      handleCategoryClick(capitalizeFirstLetter(firstActiveTag));
    }

    const loadImages = async () => {
      const updatedMenuItems = await Promise.all(
        menuItems.map(async (item) => {
          const safeImageId = item.imageId?.replace(/\s+/g, "_");
          const imageBlob = safeImageId ? await getImage(safeImageId) : null;
          const imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null;

          return { ...item, image: imageUrl };
        })
      );

      setMenuWithImages(updatedMenuItems);

      // Set filtered items based on the first active tag
      if (firstActiveTag) {
        const filtered = updatedMenuItems.filter((item) =>
          item.tags.map((tag) => tag.toLowerCase()).includes(firstActiveTag)
        );
        setFilteredItems(filtered);
      }

      return () => {
        updatedMenuItems.forEach((item) => {
          if (item.image) {
            URL.revokeObjectURL(item.image);
          }
        });
      };
    };

    loadImages();
  }, [menuItems]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);

    if (category === "All") {
      setFilteredItems(menuWithImages);
    } else {
      const filtered = menuWithImages.filter((item) =>
        item.tags.map((tag) => capitalizeFirstLetter(tag)).includes(category)
      );
      setFilteredItems([...new Set(filtered)]);
    }
  };

  const handleToggleTag = (tagName) => {
    const updatedTags = {
      ...tags,
      [tagName]: { ...tags[tagName], status: !tags[tagName].status },
    };
    setTags(updatedTags);

    const storedMenuData = ls.get("localDB_menuItems") || { toggles: {} };
    const updatedToggles = { ...storedMenuData.toggles };

    updatedToggles[tagName] = !tags[tagName].status;

    storedMenuData.toggles = updatedToggles;
    ls.set("localDB_menuItems", storedMenuData);

    const activeTags = Object.keys(updatedTags)
      .filter((tag) => updatedTags[tag].status)
      .map((tag) => tag.toLowerCase());

    if (activeTags.length > 0) {
      const filtered = menuWithImages.filter((item) =>
        item.tags.some((tag) => activeTags.includes(tag.toLowerCase()))
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(menuWithImages);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);

    const filtered = filteredItems.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {/* Title and Toggle Tags */}
      <Typography variant="h4" align="center" gutterBottom>
        Menu Preview
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        sx={{ marginBottom: "16px" }}
      >
        Toggle Tags
      </Button>

      <Divider sx={{ marginBottom: "16px" }} />

      {/* Main Layout: Sidebar for Tags & Menu Preview */}
      <Box display="flex" flexDirection="row" height="100vh">
        {/* Sidebar for Tags */}
        <Box
          sx={{
            width: "250px",
            backgroundColor: "#f4f4f4",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "8px",
            boxShadow: 3,
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Categories
          </Typography>

          {Object.keys(tags)
            .filter((tagName) => tags[tagName].status)
            .map((tagName) => (
              <Chip
                key={tagName}
                label={`${capitalizeFirstLetter(tagName)} `}
                onClick={() =>
                  handleCategoryClick(capitalizeFirstLetter(tagName))
                }
                sx={{
                  backgroundColor:
                    selectedCategory === capitalizeFirstLetter(tagName)
                      ? "#006400"
                      : "primary.main",
                  color: "white",
                  marginBottom: "8px",
                }}
              />
            ))}
        </Box>

        {/* Menu Items in Portrait Layout */}
        <Box flex="1" sx={{ padding: "16px" }}>
          <Typography variant="h3" align="center" gutterBottom>
            Restaurant Name
          </Typography>

          <TextField
            label="Search Menu"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
            sx={{ marginBottom: "16px" }}
          />

          {/* Portrait Mode Layout for Menu Items */}
          <Grid container spacing={2}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.image ? (
                      <CardMedia
                        component="img"
                        height="200"
                        image={item.image}
                        alt={item.name}
                        sx={{ objectFit: "cover" }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No Image Available
                      </Typography>
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {item.name}
                      </Typography>
                    </CardContent>
                    <Box sx={{ padding: "16px", textAlign: "center" }}>
                      <Typography variant="body2">
                        Price: PHP {item.price}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>
                No items available for selected tags or categories.
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>

      {/* Toggle Tags Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Toggle Tags</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", marginTop: 2 }}>
            {Object.keys(tags).map((tagName) => (
              <Chip
                key={tagName}
                label={`${capitalizeFirstLetter(tagName)} (${
                  tags[tagName].count
                })`}
                onClick={() => handleToggleTag(tagName)}
                sx={{
                  backgroundColor: tags[tagName].status ? "green" : "red",
                  color: "white",
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MenuPreview;
