import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ChangePasswordDialog = ({
  open,
  onClose,
  onChangePassword,
  backendError,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (backendError) {
      setIsLoading(false); 
    } else if (!backendError && !isLoading) {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  }, [backendError, isLoading]);

  const handleNewPasswordChange = (e) => {
    const inputPassword = e.target.value;
    setNewPassword(inputPassword);

    const requirements = [];

    if (!/[a-z]/.test(inputPassword)) {
      requirements.push("1 lowercase letter");
    }
    if (!/[A-Z]/.test(inputPassword)) {
      requirements.push("1 uppercase letter");
    }
    if (!/\d/.test(inputPassword)) {
      requirements.push("1 number");
    }
    if (!/[@$!%*?&]/.test(inputPassword)) {
      requirements.push("1 special character");
    }
    if (inputPassword.length < 8) {
      requirements.push("at least 8 characters");
    }

    if (requirements.length > 0) {
      setPasswordError(`Password must have ${requirements.join(", ")}.`);
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const inputConfirmPassword = e.target.value;
    setConfirmPassword(inputConfirmPassword);

    if (inputConfirmPassword !== newPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async () => {
    if (
      currentPassword &&
      newPassword &&
      !passwordError &&
      !confirmPasswordError &&
      newPassword !== currentPassword
    ) {
      setIsLoading(true);
      try {
        await onChangePassword(currentPassword, newPassword);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      if (newPassword === currentPassword) {
        setPasswordError(
          "New password cannot be the same as the current password."
        );
      }
    }
  };

  const isChangePasswordDisabled =
    !currentPassword ||
    !newPassword ||
    !confirmPassword ||
    passwordError ||
    confirmPasswordError ||
    isLoading;

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Current Password"
            type={showCurrentPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={handleNewPasswordChange}
            error={!!passwordError}
            helperText={passwordError}
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {backendError && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {backendError}
            </Typography>
          )}
          {isLoading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          color="secondary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isChangePasswordDisabled}
        >
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
