import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const OrderModal = ({ open, onClose, onOrderGenerated }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [discountType, setDiscountType] = useState("PWD");
  const [salesType, setSalesType] = useState("Dine-In");
  const [tenderType, setTenderType] = useState("Cash");
  const [loading, setLoading] = useState(false);
  const user = ls.get("user");
  const { token } = getAuthCredentials();

  useEffect(() => {
    if (open) {
      fetchMenuItems();
      setSelectedItems([]);
    }
  }, [open]);

  const fetchMenuItems = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMenuItems(data.menu.menuItems || []);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handleAddMenuItem = () => {
    setSelectedItems((prev) => [...prev, { menuItemId: "", quantity: 1 }]);
  };

  const handleMenuItemChange = (index, menuItemId) => {
    const updatedItems = [...selectedItems];
    updatedItems[index].menuItemId = menuItemId;
    setSelectedItems(updatedItems);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedItems = [...selectedItems];
    updatedItems[index].quantity = Math.max(1, Number(quantity));
    setSelectedItems(updatedItems);
  };

  const handleRemoveMenuItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };

  const handleSubmit = async () => {
    if (selectedItems.some((item) => !item.menuItemId)) {
      alert("Please select a menu item for all entries.");
      return;
    }

    setLoading(true);

    try {
      const payload = {
        posId: user.posId,
        discountType,
        salesType,
        tenderType,
        menuItems: selectedItems,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/process-order`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

        onOrderGenerated(response.data);
        alert("Sample order generated successfully.");
        onClose();
      }
    } catch (error) {
      console.error("Error processing order:", error);
      alert("Error generating order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Generate Sample Order</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {/* Discount Type */}
          <TextField
            select
            label="Select Discount Type"
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            fullWidth
            sx={{ marginTop: 1 }}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="PWD">PWD</MenuItem>
            <MenuItem value="Senior">Senior</MenuItem>
            <MenuItem value="Athlete">Athlete</MenuItem>
          </TextField>

          <TextField
            select
            label="Select Sales Type"
            value={salesType}
            onChange={(e) => setSalesType(e.target.value)}
            fullWidth
          >
            <MenuItem value="Dine-In">Dine-In</MenuItem>
            <MenuItem value="Take-Out">Take-Out</MenuItem>
            <MenuItem value="Delivery">Delivery</MenuItem>
          </TextField>

          <TextField
            select
            label="Select Tender Type"
            value={tenderType}
            onChange={(e) => setTenderType(e.target.value)}
            fullWidth
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Credit">Credit</MenuItem>
          </TextField>

          {selectedItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                select
                label="Menu Item"
                value={item.menuItemId}
                onChange={(e) => handleMenuItemChange(index, e.target.value)}
                fullWidth
              >
                {menuItems.map((menuItem) => (
                  <MenuItem key={menuItem._id} value={menuItem._id}>
                    {menuItem.menuName}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Quantity"
                type="number"
                value={item.quantity}
                onChange={(e) => handleQuantityChange(index, e.target.value)}
                sx={{ width: 100 }}
                InputProps={{ inputProps: { min: 1 } }}
              />

              <IconButton
                color="error"
                onClick={() => handleRemoveMenuItem(index)}
              >
                <Delete />
              </IconButton>
            </Box>
          ))}

          <Button variant="outlined" onClick={handleAddMenuItem}>
            Add Menu Item
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Ordering...
            </>
          ) : (
            "Generate Order"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderModal;
