import axios from "axios";
import Swal from "sweetalert2";

const handleAddInventory = async (
  newItem,
  token,
  posId,
  fetchInventory,
  setInventory,
  setOpenDialog
) => {
  try {
    const newInventory = { ...newItem, action: "Addition" };

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/create`,
      newInventory,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    Swal.fire({
      title: "Added!",
      text: `${newItem.name} has been successfully added to the inventory.`,
      icon: "success",
      confirmButtonText: "OK",
    });

    setInventory((prevInventory) => ({
      ...prevInventory,
      inventoryItems: [...prevInventory.inventoryItems, newItem], // Append newItem
    }));

    setOpenDialog(false);

    await fetchInventory(token);
  } catch (error) {
    console.error("Error adding inventory item:", error);

    Swal.fire({
      title: "Error",
      text: "Failed to add the inventory item. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

export default handleAddInventory;
