import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const HandleDeleteItem = ({ item, onClose, onDeleteConfirm, token }) => {
  const handleConfirmDelete = async () => {
    try {
      const { inventoryId, instanceIndex } = item;
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/delete-instance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { inventoryId, instanceIndex }, 
        }
      );

      Swal.fire({
        title: "Deleted!",
        text: `Instance from ${item.inventoryName} has been deleted from the inventory.`,
        icon: "success",
        confirmButtonText: "OK",
      });

      onDeleteConfirm();
    } catch (error) {
      console.error("Error deleting inventory instance:", error);

      Swal.fire({
        title: "Error!",
        text: "Failed to delete the inventory instance. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Delete Inventory Instance</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this instance of{" "}
          <strong>{item.inventoryName}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleDeleteItem;
