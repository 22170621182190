import React from "react";
import { Routes, Route } from "react-router-dom";
import DemoRoutes from "./routes/demo";
import LiveRoutes from "./routes/live";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/demo/*" element={<DemoRoutes />} />
      <Route path="/*" element={<LiveRoutes />} />
    </Routes>
  );
};

export default AppRoutes;
