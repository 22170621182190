import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getAuthCredentials } from "../../utils/auth";
import Swal from "sweetalert2";
import ReceiptHero from "./ReceiptHero";

const ReceiptTest = () => {
  const [menuItems, setMenuItems] = useState([{ name: "", price: "" }]);
  const [discountType, setDiscountType] = useState("None");
  const { token } = getAuthCredentials();

  const handleAddRow = () => {
    setMenuItems([...menuItems, { name: "", price: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedItems = menuItems.filter((_, i) => i !== index);
    setMenuItems(updatedItems);
  };

  const handleChange = (index, field, value) => {
    const updatedItems = [...menuItems];
    updatedItems[index][field] = value;
    setMenuItems(updatedItems);
  };

  const handleGenerateReceipt = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-receipt`,
        { menuItems, discountType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const receiptBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const receiptURL = URL.createObjectURL(receiptBlob);

      window.open(receiptURL, "_blank");
    } catch (error) {
      console.error("Error generating receipt:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to generate receipt.",
      });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ReceiptHero />
      <Typography variant="h5" gutterBottom>
        Test Print Receipt
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Discount Type</InputLabel>
        <Select
          value={discountType}
          onChange={(e) => setDiscountType(e.target.value)}
          label="Discount Type"
        >
          <MenuItem value="None">None</MenuItem>
          <MenuItem value="PWD">PWD</MenuItem>
          <MenuItem value="Senior">Senior</MenuItem>
          <MenuItem value="Athlete">Athlete</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={2}>
        {menuItems.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <TextField
                label="Menu Item"
                variant="outlined"
                fullWidth
                value={item.name}
                onChange={(e) => handleChange(index, "name", e.target.value)}
              />
              <TextField
                label="Price"
                variant="outlined"
                fullWidth
                value={item.price}
                onChange={(e) => handleChange(index, "price", e.target.value)}
                type="number"
              />
              <IconButton
                color="error"
                onClick={() => handleRemoveRow(index)}
                disabled={menuItems.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddRow}
        >
          Add Item
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleGenerateReceipt}
        >
          Generate Receipt
        </Button>
      </Box>
    </Box>
  );
};

export default ReceiptTest;
